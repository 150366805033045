$(document).on('turbolinks:load', function() {
  var debounceTimeout;
  $('body').on('change', '.job_location_field', function() {
    clearTimeout(debounceTimeout);
    debounceTimeout = setTimeout(function () {
      var jobLocations = $('.job_location_field');
      var locationValues = [];
      jobLocations.each(function() {
        var value = $(this).val();
        locationValues.push(value);
      });

      var uniqueSet = new Set(locationValues);
      var hasDuplicates = locationValues.length !== uniqueSet.size;

      if (hasDuplicates) {
        $('#job_location_validation_error').text('A location cannot be added twice.');
        $('#postButton').prop('disabled', true);
        $('#job_location_validation_error').removeClass('hidden');
      }else{
        $('#postButton').prop('disabled', false);
        $('#job_location_validation_error').addClass('hidden');
      }
    }, 500);
  });

  $('#job_company_jobable_id, #job_research_jobable_id, #job_school_jobable_id').on('change', function() {
    var array = ['job_company_jobable_id', 'job_research_jobable_id', 'job_school_jobable_id'];
    var i = 0, len = array.length;
    while (i < len) {
      if(array[i] !== this.id){
        $('#' + array[i]).val('');
        $('.select_jobable').selectpicker('refresh');
      }
      i++;
    }
  });

  $("input[name='job[job_type]']").change(function() {
    if ($(this).val() == "remote") {
      $(".job_location_field").prop("disabled", true);
    } else {
      $(".job_location_field").prop("disabled", false);
    }
  });

  var maxLength = 300;
  var readMore = ' <span class="line_break_span">...</br></span><a href="javascript:void(0);" class="read-more">Read more...</a>';
  var showLess = ' <a href="javascript:void(0);" class="show_less"> </br>Show less...</a>';
  $(".show-read-more").each(function(){
      var myStr = $(this).text();
      if(myStr.length > maxLength){
          var newStr = myStr.substring(0, maxLength);
          var removedStr = myStr.substring(maxLength, myStr.length);
          $(this).empty().html(newStr);
          $(this).append(readMore);
          $(this).append('<span class="more-text d-none">' + removedStr + '</span>');
      }
  });
  $('body').on('click', '.read-more', (e)=>{
    var currentObj = $(e.target);
    currentObj.siblings(".more-text").removeClass("d-none");
    currentObj.siblings(".more-text").after(showLess);
    currentObj.siblings(".line_break_span").remove();
    currentObj.remove();
  });
  $('body').on('click', '.show_less', (e)=>{
    var currentObj = $(e.target);
    currentObj.siblings(".more-text").addClass('d-none');
    currentObj.parent().append(readMore);
    currentObj.remove();
  });
  $('.save_job').on('click', ()=>{
    if($("#job_create").data('url')){
     url = $("#job_create").data('url');
    }
    else{
      url = "/jobs/"
    }
    if($("#existing_job_id").val()){
      $.ajax({
        url: url + $("#existing_job_id").val(),
        type: 'PATCH',
        dataType: 'json',
        data: $('#job_create').serialize(),
        success:function() {
        }
      });
    }
    else{
      $.ajax({
        url: url,
        type: 'POST',
        dataType: 'json',
        data: $('#job_create').serialize(),
        success:function(response) {
          $('#existing_job_id').val(response['id']);
        }
      });
    }
  });

  $('body').on('keyup', '.searchJob', (e) => {
      let $this = $(e.currentTarget)
      let authenticity_token = $('meta[name=csrf-token]').attr('content')
      let search = $this.val()

      $('.searchJob').val(search)

      $('#jobs-here').load('jobs/filter', { authenticity_token, search })
  });

  function remove_benifits_tag(e){
    var text = e.target.parentElement.dataset["benfitsOption"];
    var regex = new RegExp(text + '\, |\, ' + text, 'g');
    var regex_last = new RegExp(text, 'g');
    $(".job_benifits button .filter-option-inner-inner").text($(".job_benifits button .filter-option-inner-inner").text().replace(regex , "").replace(regex_last, 'Select Benefits'));
    $('#job_benifits option[value="'+ e.target.parentElement.dataset["benfitsVal"] +'"]').prop('selected', false);
    $('#job_benifits').trigger('change');
  }

  function remove_skill_tag(e){
    var th = $(e.target);
    var selectedVal = th.parent().data('id');
    var jobSkillVal = document.getElementsByClassName('job_skill');
    for(var i=0; i<jobSkillVal.length; i++ ){
      if(jobSkillVal[i].getAttribute("id") === selectedVal){
        jobSkillVal[i].closest(".nested-fields").setAttribute("Style", "display: none;");
        if(jobSkillVal[i].parentElement.nextElementSibling){
          jobSkillVal[i].parentElement.nextElementSibling.value = 1;
        }
        else{
          jobSkillVal[i].nextElementSibling.value = 1;
        }
      }
    }
    load_job_skills();
  }

  function append_selected_value(option_name, option_value, select_id){
    var selected_job_skill = "<span class='d-flex create_job_skills_tag appended_selected_value' data-option = '"+ option_name +"' data-id = '"+ select_id +"'><span class='d-flex create_job_skills_tag_text'>"+ option_value +"</span><span class='d-flex create_job_skills_tag_remove_link'></span></span>";
    $('.selected_job_skill').append(selected_job_skill);
  }

  function load_job_skills(){
    $('.selected_job_skill').html("");
    var jobSkillVal = document.getElementsByClassName('job_skill');
    for(var i=0; i<jobSkillVal.length; i++ ){
      if(jobSkillVal[i].value){
        if(jobSkillVal[i].closest(".nested-fields").getAttribute("style") === "display: none;"){
          continue;
        }
        else{
          append_selected_value(jobSkillVal[i].value, jobSkillVal[i].options[jobSkillVal[i].selectedIndex].text, jobSkillVal[i].getAttribute("id"));
        }
      }
    }
  }

  function load_benifits(){
    var benifits_val = $('#job_benifits').val();
    var benefits_option = $('[data-benfits-val]');
    if(benefits_option.length > 0){
      for (var i = 0; i < benefits_option.length; i++) {
        if($.inArray(benefits_option[i].dataset["benfitsVal"], benifits_val) !== -1){
          benefits_option[i].classList.remove('d-none');
          benefits_option[i].classList.add('d-flex');
        }
        else{
          benefits_option[i].classList.add('d-none');
          benefits_option[i].classList.remove('d-flex');
        }
      }
    }
  }

  function jobInterested(th){
    var currentBtn= th;
    $.ajax({
      url: '/jobs/'+ th.data('id') +'/interested',
      type: 'GET',
      dateType: 'script'
    });
  }

  $('body').on('click', '.create_job_skills_tag_remove_link', (e)=>{
    remove_skill_tag(e);
  });

  $('body').on('click', '.create_job_benefit_remove_link', (e)=>{
    remove_benifits_tag(e);
  });

  $('body').on('change', '#job_benifits', ()=>{
    load_benifits();
  });

  $('body').on('change', '.job_skill', ()=>{
    load_job_skills();
  });

  $('.job_skill_container').on('cocoon:after-remove', function() {
    load_job_skills();
  });
  $('body').on('click', '.interested_job', function() {
    jobInterested($(this));
  });

  load_job_skills();
  load_benifits();

  $('body').on('click', '.job_type_filter', (e)=>{
    $('.jobs_side_filter').submit();
  })

});
