$(document).on('turbolinks:load', function() {
  $('body').on('keyup', '.searchSummit', (e) => {
    let $this = $(e.currentTarget)
    let authenticity_token = $('meta[name=csrf-token]').attr('content')
    let search = $this.val()
    $('.searchSummit').val(search)
    $('#summits-here').load('/admin/summits/filter', { authenticity_token, search })
  })

  $('body').on('change', '#summit_summitable_type', (e) => {
    let $this = $(e.currentTarget)
    let selected = $this.val()
    let url = $this.data('url')
    $.ajax({
      url: url,
      dataType: 'script',
      type: 'GET',
      data: {selected: selected}
    })
  })
  var fields = [
    {selectors: ['#summit_time', '#summit_review_deadline' ], isRangePicker: true},
    {selectors: ['#summit_deadline', '#summit_acceptance_deadline', '#date_to_notify_accepted_users', '#date_to_notify_rejected_users'], isRangePicker: false}
  ];
  fields.forEach(function (fieldSet) {
    fieldSet.selectors.forEach(function (selector) {
      var $field = $(selector);
      if ($field.length) {
        var deadlineValue = $field.val();
        var startDate, endDate, formattedDeadline, formattedDeadlineEnd;
        if (deadlineValue) {
          if (fieldSet.isRangePicker) {
            var deadlineParts = deadlineValue.split(' - ');
            startDate = moment(deadlineParts[0], 'MMM D, YYYY');
            endDate = moment(deadlineParts[1], 'MMM D, YYYY');
            formattedDeadline = startDate.format("MMM D, YYYY");
            formattedDeadlineEnd = endDate.format("MMM D, YYYY");
          }
          else {
            startDate = moment(deadlineValue, 'MMM D, YYYY');
            formattedDeadline = startDate.format("MMM D, YYYY");
          }
        }
        jquery($field[0]).daterangepicker({
          singleDatePicker: !fieldSet.isRangePicker,
          autoUpdateInput: false,
          locale: {
            format: 'MMM D, YYYY'
          }
        });

        jquery($field[0]).on('cancel.daterangepicker', function(ev, picker) {
          $(this).val('');
        });

        jquery($field[0]).on('hide.daterangepicker', function(ev, picker) {
          var selectedStartDate = picker.startDate.format('MMM D, YYYY');
          var selectedEndDate = picker.endDate.format('MMM D, YYYY');

          if (fieldSet.isRangePicker) {
            $(this).val(selectedStartDate + ' - ' + selectedEndDate);
          } else {
            $(this).val(selectedStartDate);
          }
        });
      }
    });
  });
})
