$(document).on('turbolinks:load', function () {
  $('body').on('click', '.privacy_setting_btn', (e)=>{
    th = $(e.target).closest('a');
    $.ajax({
      url: '/users/' + th.data('userId') + '/privacy_settings/'+ th.data('id') +'/edit',
      type: "get",
      data: {pubmed_id: th.data('pubmed-id')}
    })
  })
  $('body').on('change', 'input[type=radio][name=privacy_settings_radio]', (e)=>{
    th = $(e.target)
    $.ajax({
      url: '/pubmed_papers/' + 'update_privacy',
      type: "GET",
      data: {privacy_type: th.val(), pubmed_id: th.data('pubmed-id'), user_id: th.data('user_id')}
    })
  })
  $('body').on('keyup', '#search-users-to-invite', (e)=>{
    th = $(e.target)
    const id = parseInt($('#users-for-invite').attr('value'));
    if (name.length == 0 || name.length >= 3){
      $.ajax({
        url: '/users/'+ id +'/privacy_settings/'+ th.data('id') +'/filter_users/',
        method: 'GET',
        data: { search: th.val() }
      })
    }
  })
})
