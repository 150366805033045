$(document).on('turbolinks:load', function() {
  jquery('#new_user').validate({
    rules: {
      'user[first_name]': {
        required: true,
        notblank: true,
        minlength: 1,
        maxlength: 50,
        validName: true,
      },
      'user[last_name]': {
        required: true,
        notblank: true,
        minlength: 1,
        maxlength: 50,
        validName: true,
      },
      'user[password]': {
        required: true,
        notblank: true,
        minlength: 8,
        maxlength: 50
      },
      'user[password_confirmation]': {
        required: true,
        notblank: true,
        minlength: 8,
        maxlength: 50,
        equalTo: "#pwd"
      },
      'user[email]': {
        required: true,
        isEmail: true,
        maxlength: 50,
        email: true,
        remote: {
          url: '/email_exist',
          sendParam: 'email',
          method: 'POST',
        }
      }
    },
    messages: {
      'user[email]': {
        remote: 'Email already exist.'
      }
    },
    errorPlacement: function(error, element) {
      error.insertAfter(element.parent());
    }
  });

  jquery.validator.addMethod("notblank", function(value) {
    return (value !== '' && value.trim() !== '');
  }, "This field can't be blank.");

  jquery.validator.addMethod("validName", function(value) {
    var regex = /^[A-Za-z'\-\s]+$/;
    return regex.test(value);
  }, "Name should consist of letters, apostrophe, or hyphens only.");

  jquery.validator.addMethod("isEmail", function(value) {
    var regex = /^((?!.*@(test|mailinator)\b)([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    return regex.test(value);
  }, "Email is not valid.");
});
