$(document).on('turbolinks:load', function() {
    $('#management_tab').click(() => {
        let slug = window.location.pathname.split('/')[2];
        filterEventManagement(slug, 'management-attendees-here', true);
    });

    $('#interested_tab').click(() => {
        let slug = window.location.pathname.split('/')[2];
        filterEventManagement(slug, 'management-interested-here', false);
    });

    $(`#event_management_attendee_search, #event_management_attendee_role`).on('input change', (e) => {
        let slug = $(e.currentTarget).data('slug');
        let containerId = $('#management_tab').hasClass('active') ? 'management-attendees-here' : 'management-interested-here';
        let isAttendeeSearch = $('#management_tab').hasClass('active');
        filterEventManagement(slug, containerId,isAttendeeSearch);
    });

    $("td[class^=td_status_]").click(function() {
        var type = $(this).data('type') || 'event'
        if ($(this).find(`[id^="status_select_tag_"]`).hasClass('d-none')){
            $(this).find(`[id^="status_select_tag_"]`).removeClass('d-none');
            $(this).find(`[id^="${type}_status_"]`).addClass('d-none');
        } else {
            $(this).find(`[id^="status_select_tag_"]`).addClass('d-none');
            $(this).find(`[id^="${type}_status_"]`).removeClass('d-none');
        }
    });

    $("select[id^=select_status_]").on({
        click: e => {
            e.stopPropagation()
        },
        change: function () {
            var parent = $(this).closest('td')
            var selected_text =  $(this).find('option:selected').text();
            var id  = $(this).data('id');
            var name  = $(this).data('name')
            var pervious_status = $(this).data('status')
            var type = $(this).data('type') || 'events';
            var moduleType = $(this).closest('td[class^=td_status]').data('type') || 'event';
            var status_value = $(this).data('status-id')
            var status =  $(this).val();
            var success = confirm(`Are you sure you want to approve ${name} ?`);
            if(success)
            {
                $.ajax({
                    url: `/admin/${type}/${id}/update_status`,
                    type: 'get',
                    data: {status, id},
                    success:function(response){
                        parent.find(`[id^="status_select_tag_"]`).addClass('d-none');
                        parent.find(`[id^="${moduleType}_status_"]`).removeClass('d-none');
                        parent.find(`[class*="labelname_"]`).text(selected_text);
                    }
                })
            }
            else
            {
                parent.find(`[id^="status_select_tag_"] option[value= ${status_value}]`).prop('selected',true);
                parent.find(`[id^="status_select_tag_"]`).addClass('d-none');
                parent.find(`[id^="${moduleType}_status_"]`).removeClass('d-none');
                parent.find(`[class*="labelname_"]`).text(pervious_status);
            }
        }
    })

    function filterEventManagement(slug, containerId, isAttendeeSearch) {
      if (!slug) return false;

      let authenticity_token = $('meta[name=csrf-token]').attr('content');
      let role = $(`#event_management_attendee_role`).val();
      let search = $(`#event_management_attendee_search`).val();
      let attendeeType = isAttendeeSearch ? 'attendee' : 'interested';

      $(`#${containerId}`).load(`/events/${slug}/management/filter`, { authenticity_token, role, search,attendeeType }, () => {
          $('.selectpicker').selectpicker();
      });
    }
})