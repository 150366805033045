$(document).on('turbolinks:load', function() {
  if ($("#primary-menu-toggle").length) {
    document.getElementById('primary-menu-toggle').addEventListener('click', (e) => {
      e.preventDefault();
      document.getElementById('primary-menu').classList.toggle('nav-menu--minimized');
    });
  }

  if ($("#primary-menu-more").length) {
    document.getElementById('primary-menu-more').addEventListener('click', (e) => {
      e.preventDefault();
      document.getElementById('primary-menu').classList.toggle('nav-menu--full');
    });
  }

  $(document).on('change', '#event_company_eventable_id, #event_research_eventable_id, #event_school_eventable_id, #event_group_eventable_id, #event_community_eventable_id', function() {
    var array = ['event_company_eventable_id', 'event_research_eventable_id', 'event_school_eventable_id', 'event_group_eventable_id', 'event_community_eventable_id'];
    var i = 0, len = array.length;
    while (i < len) {
      if(array[i] !== this.id){
        $('#' + array[i]).val('');
      }
      i++;
    }
  });

  $('[name="nih_institutes_and_center[logo]"]').change(e => {
    const $this = $(e.currentTarget)
    if ($this[0].files.length) {
      if ($this.attr('name') === 'nih_institutes_and_center[logo]') {
        content = `<span class="d-flex create_job_upload_media_logo">
                      <img style="width: 250px; max-width: 100%; max-height: 100%;" src="${URL.createObjectURL($this[0].files[0])}"/>
                    </span>
                    <span class="d-flex create_job_upload_media_text">
                      Image Preview
                    </span>`
      }
    }

    let labelFor = ''
    if ($this.attr('name') === 'nih_institutes_and_center[logo]') {
      labelFor = 'nih_institutes_and_center_logo'
    }
    $(`[for="${labelFor}"] > span`).html(content)
  })

});