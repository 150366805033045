import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["start", "stop"]
  streamContainers = {};
  itemContainers = {};

  connect() {
    this.archive;
    this.stopTarget.classList.add('display-none')
    this.apiKey = this.data.get("apiKey")
    this.sessionId = this.data.get("sessionId")
    this.token = this.data.get("token")
    this.initializeSession()
    this.session.on('signal', (event) => {
      const data = JSON.parse(event.data);
      switch (data.action) {
      case 'toggleSubscriberAudio':
        this.toggleSubscriberAudio(data, data.value);
        break;

      case 'toggleSubscriberVideo':
        this.toggleSubscriberVideo(data, data.value);
        break;

      default:
        console.warn('Unknown action type in signal:', data.action);
      }
    });
    this.session.on('streamDestroyed', function(event) {
      const user_id = event.stream.name;
      const container = this.streamContainers[user_id];
      const item = this.itemContainers[user_id];
      if (container) {
        container.remove();
        delete this.streamContainers[user_id];
      }
      if (item) {
        item.remove();
        delete this.itemContainers[user_id];
        // this.removeWebinarUser(user_id);
      }
      setTimeout(() => {
        const noSpeakers = $('#first_persons')[0].children[3] === undefined;
        const noOtherPerson = $('#other_persons')[0].children[1] === undefined;
        if (noSpeakers && noOtherPerson) {
          this.showStartShortlyMessage();
        }
      }, 100);
    }.bind(this));
     this.mainVideoContainer = $('#first_persons')[0];
     this.otherPersonsContainer = $('#other_persons')[0];
     $(this.otherPersonsContainer).on('click', '.webinar_room_participant_item', (event) => {
      const userId = event.currentTarget.id.match(/\d+/);
        if (userId) {
          console.log(userId[0]);
        }
       const clickedUser = $(event.currentTarget)[0].children[1];
       const other_subscriber = this.session.streams[userId];
       other_subscriber.subscribeToVideo(false);
       const firstName =  $('.webinar_room_presenter_name')[0].innerHTML;
       $('.webinar_room_presenter_name')[0].innerHTML = clickedUser.parentElement.children[0].children[0].innerHTML;
       clickedUser.parentElement.children[0].children[0].innerHTML = firstName;
       this.swapContainersContent(clickedUser);
       other_subscriber.subscribeToVideo(true);
       this.publisher.publishVideo(true);
     });
  }

  swapContainersContent(clickedUser) {
    const mainVideoContentCopy = this.mainVideoContainer.children[3];
    const otherparent = clickedUser.parentElement;
    this.mainVideoContainer.children[3].replaceWith(clickedUser);
    otherparent.append(mainVideoContentCopy);
  }

  updateAudioIcon(subscriberId, value) {
    const isPublisher = this.publisher.stream.name === subscriberId.subscriberId;
    const audioButton = $(`.user_mic_${subscriberId.subscriberId}`);
    if (value) {
      audioButton.removeClass('mic_off').addClass('mic_on');
    } else {
      audioButton.removeClass('mic_on').addClass('mic_off');
    }
  }

  updateVideoIcon(subscriberId, value) {
    const isPublisher = this.publisher.stream.name === subscriberId.subscriberId;
    const videoButton = $(`.user_camera_${subscriberId.subscriberId}`);
    if (value) {
      videoButton.removeClass('cam_off').addClass('cam_on');
    } else {
      videoButton.removeClass('cam_on').addClass('cam_off');
    }
  }

  toggleSubscriberAudio(subscriberId, value) {
    const isPublisher = this.publisher.stream.name === subscriberId.subscriberId;
    this.updateAudioIcon(subscriberId, value);
    if (isPublisher) {
      this.publisher.publishAudio(value);
    } else {
      const subscriber = this.session.streams[subscriberId.subscriberId];
      if (subscriber) {
        subscriber.subscribeToAudio(value);
      }
    }
  }

  toggleSubscriberVideo(subscriberId, value) {
    const isPublisher = this.publisher.stream.name === subscriberId.subscriberId;
    this.updateVideoIcon(subscriberId, value);
    if (isPublisher) {
      this.publisher.publishVideo(value);
    } else {
      const subscriber = this.session.streams[subscriberId.subscriberId];
      if (subscriber) {
        subscriber.subscribeToVideo(value);
      }
    }
  }

  sendSignalToSubscribers(data) {
    this.session.signal({
      data: JSON.stringify(data),
    }, (error) => {
      if (error) {
        console.error('Error sending signal:', error);
      }
    });
  }

  toggleSubscriberAudioWithSignal(subscriberId, value) {
    this.sendSignalToSubscribers({
      action: 'toggleSubscriberAudio',
      subscriberId: subscriberId,
      value: value,
    });
  }

  toggleSubscriberVideoWithSignal(subscriberId, value) {
    this.sendSignalToSubscribers({
      action: 'toggleSubscriberVideo',
      subscriberId: subscriberId,
      value: value,
    });
  }

  showStartShortlyMessage() {
    const startShortlyMessage = $(`
      <div class="container mt-5" id="startShortlyMessage">
      <div class="alert alert-info" role="alert">
      <h4 class="alert-heading">The webinar will start shortly</h4>
      <p class="mb-0">Please stay tuned for the upcoming event.</p>
      </div>
      </div>`
      );

    $('#first_persons').append(() => startShortlyMessage);
    $('#fullscreenButton, .webinar_room_presenter_name').addClass('hide-important');
  }

  disconnect() {
    if (this.session) {
      this.session.disconnect()
    }
  }

  initializeSession() {
    this.session = OT.initSession(this.apiKey, this.sessionId);
    this.session.on('streamCreated', this.streamCreated.bind(this));
    const firstPersons = $('#first_persons');
    let isUserOnSpeakersList = firstPersons.data('isSpeaker');

    this.publisher = OT.initPublisher(firstPersons[0], {
      insertMode: 'append',
      width: '100%',
      height: '100%',
      name: this.data.get("speaker"),
      publishVideo: isUserOnSpeakersList,
    }, this.handleError.bind(this));
    console.log(this.publisher);
    if (!isUserOnSpeakersList) {
      firstPersons[0].children[3].remove();
    }

    const toggleCameraButton = $('#toggle-camera-button');
    var user_id = this.data.get("speaker");
    const micButton = $(`.user_mic_${user_id}`);
    const cameraButton = $(`.user_camera_${user_id}`);

    const currentUrl = window.location.href;
    const slug = extractWebinarSlug(currentUrl);
    console.log(`Webinar Slug: ${slug}`);

    function extractWebinarSlug(url) {
      const match = url.match(/\/webinars\/([^\/]+)/);
      return match ? match[1] : null;
    }

    fetch('/webinar_speaker', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        user_id: user_id,
        webinar_slug: slug
      }),
    })
    .then(response => {
      if (!response.ok) {
        throw new Error(`Server returned ${response.status}`);
      }
      return response.json();
    })
    .then(data => {
      var webinarSpeaker = data.webinar_speaker;
      console.log(`Webinar Speaker: ${webinarSpeaker}`);
      const user_name = data.user_name


      if (!webinarSpeaker) {
      const newUserContainer = $(
        `<span class="d-flex webinar_room_speakers_item" data-participant-id="${user_id}">
          <span class="d-flex webinar_room_speakers_item_left">
            <img class="avatar-img" src="${data.pictureurl}" alt="Participant Image">
            <span class="d-inline-block webinar_room_speakers_item_name">
              ${user_name}
            </span>
          </span>
          <span class="d-flex webinar_room_speakers_item_right" style="margin: 15px;">
            <strong><a class="webinar_room_speakers_item_action" style="color: #32C78A;" onclick="openChatSection()">Chat</a></strong>
          </span>
        </span>`
      );

        $("#webinar_room_participants_user").append(newUserContainer);
        this.itemContainers[user_id] = newUserContainer;
      }
    })
    .catch(error => {
      console.error('Error fetching data:', error);
    });

    cameraButton.on('click', () => {
      isUserOnSpeakersList = !isUserOnSpeakersList;
      this.toggleSubscriberVideoWithSignal(user_id, isUserOnSpeakersList);
      if (user_id) {
        if (!isUserOnSpeakersList) {
          cameraButton.removeClass('cam_on').addClass('cam_off');
        } else {
          cameraButton.removeClass('cam_off').addClass('cam_on');
        }
      }
    });

    micButton.on('click', () => {
      isUserOnSpeakersList = !isUserOnSpeakersList;
      this.toggleSubscriberAudioWithSignal(user_id, isUserOnSpeakersList);
      if (user_id) {
         if (!isUserOnSpeakersList) {
          micButton.removeClass('mic_on').addClass('mic_off');
        } else {
          micButton.removeClass('mic_off').addClass('mic_on');
        }
      }
    });
    $('.OT_edge-bar-item').css('display', 'none');

    this.updateCameraUI = (isCameraOn) => {
      if (isCameraOn) {
        console.log('Camera turned on');
      } else {
        console.log('Camera turned off');
      }
    };
    this.session.connect(this.token, (error) => {
      if (error) {
        this.handleError(error);
      } else {
        this.session.connection.data = 'isSpeaker:' + isUserOnSpeakersList;

        this.streamConnected();
      }
    });
    console.log(this.session);
    this.session.on('archiveStarted', this.archiveStarted.bind(this));
    this.session.on('archiveStopped', this.archiveStopped.bind(this));
  }

  streamConnected(error) {
    if (error) {
      this.handleError(error);
    } else {
      this.session.publish(this.publisher, {
        data: 'additionalDataHere',
      }, this.handleError.bind(this));
    }
  }

  streamCreated(event) {
    const elm = $('#other_persons');
    var webinarSpeaker = false;
    var user_id = event.stream.name;

    const currentUrl = window.location.href;
    const slug = extractWebinarSlug(currentUrl);
    console.log(`Webinar Slug: ${slug}`);

    function extractWebinarSlug(url) {
      const match = url.match(/\/webinars\/([^\/]+)/);
      return match ? match[1] : null;
    }

    fetch('/webinar_speaker', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        user_id: user_id,
        webinar_slug: slug
      }),
    })
    .then(response => {
      if (!response.ok) {
        throw new Error(`Server returned ${response.status}`);
      }
      return response.json();
    })
    .then(data => {
      webinarSpeaker = data.webinar_speaker;
      console.log(`Webinar Speaker: ${webinarSpeaker}`);
      const user_name = data.user_name


      if (!webinarSpeaker) {
      const newUserContainer = $(
        `<span class="d-flex webinar_room_speakers_item" data-participant-id="${user_id}">
          <span class="d-flex webinar_room_speakers_item_left">
            <img class="avatar-img" src="${data.pictureurl}" alt="Participant Image">
            <span class="d-inline-block webinar_room_speakers_item_name">
              ${user_name}
            </span>
          </span>
          <span class="d-flex webinar_room_speakers_item_right" style="margin: 15px;">
            <strong><a class="webinar_room_speakers_item_action" style="color: #32C78A;" onclick="openChatSection()">Chat</a></strong>
          </span>
        </span>`
      );

        $("#webinar_room_participants_user").append(newUserContainer);
        this.itemContainers[event.stream.name] = newUserContainer;
      }

      if (webinarSpeaker) {
        const firstPersons = $('#first_persons');
        if (firstPersons[0].children[3] == undefined || firstPersons[0].children[3].id == "startShortlyMessage") {
          $('#startShortlyMessage').remove();
          $('#fullscreenButton').removeClass('hide-important');
          $('.webinar_room_presenter_name').removeClass('hide-important');
          var subscriber = this.session.subscribe(event.stream, firstPersons[0], {
            insertMode: "append",
            width: "100%",
            height: "100%",
          }, this.handleError.bind(this));
          $('.webinar_room_presenter_name').html(user_name);
        } else {

          const newStreamContainer = $(`
            <span class="d-inline-block webinar_room_participant_item" id="user_other_${user_id}">
            <span class="d-flex webinar_room_participant_item_bottom">
            <span class="d-inline-block webinar_room_participant_item_name" style="z-index: 1;">${user_name}</span>
            </span>
            </span>
            `);

          elm.append(newStreamContainer);
          this.streamContainers[event.stream.name] = newStreamContainer;
          if (webinarSpeaker) {
            var subscriber = this.session.subscribe(event.stream, newStreamContainer[0], {
              insertMode: "append",
              width: "100%",
              height: "100%",
            }, this.handleError.bind(this));
          } else {}
        }
        $('.OT_edge-bar-item').css('display', 'none');
        if (!this.session.streams[user_id]) {
          this.session.streams[user_id] = subscriber;
        }
        const micButton = $(`.user_mic_${event.stream.name}`);
        const cameraButton = $(`.user_camera_${event.stream.name}`);
        var hasVideo = event.stream.hasVideo;
        var hasAudio = event.stream.hasAudio;
        updateCameraButtonStyle(hasVideo);
        updateMicButtonStyle(hasAudio);

        cameraButton.on('click', () => {
          const otherUserId = event.stream.name;
          const otherUserSubscriber = this.session.streams[otherUserId];

          if (otherUserSubscriber) {
            if (hasVideo) {
              cameraButton.removeClass('cam_on').addClass('cam_off');
            } else {
              cameraButton.removeClass('cam_off').addClass('cam_on');
            }
            this.toggleSubscriberVideoWithSignal(otherUserId, !hasVideo);
            hasVideo = !hasVideo;
          }
        });

        function updateCameraButtonStyle(hasVideo) {
          if (hasVideo) {
            cameraButton.removeClass('cam_off').addClass('cam_on');
          } else {
            cameraButton.removeClass('cam_on').addClass('cam_off');
          }
        }

        micButton.on('click', () => {
          const otherUserId = event.stream.name;
          const otherUserSubscriber = this.session.streams[otherUserId];
          
          if (otherUserSubscriber) {
             if (hasAudio) {
              micButton.removeClass('mic_on').addClass('mic_off');
            } else {
              micButton.removeClass('mic_off').addClass('mic_on');
            }
            this.toggleSubscriberAudioWithSignal(otherUserId, !hasAudio);
            hasAudio = !hasAudio;
          }
        });

        function updateMicButtonStyle(hasAudio) {
          if (hasAudio) {
            micButton.removeClass('mic_off').addClass('mic_on');
          } else {
            micButton.removeClass('mic_on').addClass('mic_off');
          }
        }
      }
    })
  .catch(error => {
      console.error('Error fetching data:', error);
    });

   console.log(event.stream.connection);
  }

  removeWebinarUser(user_id) {
    const currentUrl = window.location.href;
    const slug = extractWebinarSlug(currentUrl);
    console.log(`Webinar Slug: ${slug}`);

    function extractWebinarSlug(url) {
      const match = url.match(/\/webinars\/([^\/]+)/);
      return match ? match[1] : null;
    }
    fetch('/webinar_user', {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        user_id: user_id,
        webinar_slug: slug
      }),
    })
    .then(response => {
      if (!response.ok) {
        throw new Error(`Server returned ${response.status}`);
      }
      return response.json();
    })
    .then(data => {})
    .catch(error => {
      console.error('Error fetching data:', error);
    });
  }

  archiveStarted(event) {
    this.archive = event
    console.log('Archive started ' + event.id);
    this.startTarget.classList.add('display-none')
    this.stopTarget.classList.remove('display-none')
    this.stopTarget.classList.add('display-inline')
  }

  archiveStopped(event) {
    this.archive = event
    console.log('Archive stopped ' + event.id);
    this.startTarget.classList.remove('display-none')
    this.startTarget.classList.add('display-inline')
    this.stopTarget.classList.add('display-none')
    this.stopTarget.classList.remove('display-inline')
  }

  handleError(error) {
    if (error) {
      console.log(error.mesage)
    }
  }

  async postRequest(url = '', data = {}) {
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      });
      if (!response.ok) {
        throw new Error('error getting data!');
      }
      return response.json();
    } catch (error) {
      this.handleError(error);
    }
  }

  async getRequest(url = '') {
    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      });
      if (!response.ok) {
        throw new Error('error getting data!');
      }
      return response.json();
    } catch (error) {
      this.handleError(error);
    }
  }

  async startArchiving() {
    console.log('start archiving');
    try {
      this.archive = await this.postRequest('/archive/start', {
        'session_id': this.sessionId
      });
      console.log('archive started: ', this.archive);
      if (this.archive.status !== 'started') {
        this.handleError(this.archive.error);
      } else {
        console.log('successfully started archiving: ', this.archive);
      }
    } catch (error) {
      this.handleError(error);
    }
  }

  async stopArchiving() {
    console.log('stop archiving');
    try {
      this.archive = await this.getRequest(`/archive/${this.archive.id}/stop?vonage_id=${this.element.dataset.vonageId}`);
      console.log('archive stopped: ', this.archive);
      if (this.archive.status !== 'stopped') {
        this.handleError(this.archive.error);
      } else {
        console.log('successfully stopped archiving: ', this.archive);
      }
    } catch (error) {
      this.handleError(error);
    }
  }
}