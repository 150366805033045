$(document).on('turbolinks:load', function() {

  $(function () {
    var minlength = 3;

    $('body').on('keyup', '.organization_search_field', (e)=> {
      var th = $(e.target);
      if (th.val().length >= minlength || e.keyCode === 8 ) {
        $.ajax({
          type: "GET",
          url: th.data('url'),
          data: {
              'search' : th.val()
          },
          dataType: 'script',
          success: function(msg){
          }
        });
      }
    });

    $('body').on('keyup', '.organization_job_search_field', (e)=> {
      var th = $(e.target);
      if (th.val().length >= minlength || e.keyCode === 8 ) {
        $.ajax({
          type: "GET",
          url: th.data('url'),
          data: { 'search' : th.val() },
          dataType: 'script',
        });
      }
    });
  });
});
