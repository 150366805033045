$(document).on('turbolinks:load', function() {
  $('.selectpicker').selectpicker();
  $('.create_job_form_category_select').selectpicker();

  $('.user_profile_form_photo.uploaded_photo')
  .mouseenter(function() {
    $(this).find('.user_profile_form_photo_delete').addClass('show');
  })
  .mouseleave(function() {
    $(this).find('.user_profile_form_photo_delete').removeClass('show');
  });

  $('.webinar_room_chat_toggle').on('click', function(e) {
    e.stopPropagation();
    e.preventDefault();

    var tabData = $(this).data("tab");

    $('.webinar_room_chat_toggle').removeClass('active');
    $('.webinar_room_speakers_items_container.chat_container').removeClass('active');
    $('.webinar_room_chat_form_container').removeClass('active');

    $('.webinar_room_speakers_items_container.chat_container[data-tab="' + tabData + '"]').addClass('active');
    $('.webinar_room_chat_form_container[data-tab="' + tabData + '"]').addClass('active');
    $(this).addClass('active');
  });

  $('.user_room_chat_toggle').on('click', function(e) {
    e.stopPropagation();
    e.preventDefault();

    var tabData = $(this).data("tab");

    $('.user_room_chat_toggle').removeClass('active');
    $('.user_room_speakers_items_container.chat_container').removeClass('active');
    $('.user_room_chat_form_container').removeClass('active');

    $('.user_room_speakers_items_container.chat_container[data-tab="' + tabData + '"]').addClass('active');
    $('.user_room_chat_form_container[data-tab="' + tabData + '"]').addClass('active');
    $(this).addClass('active');
  });

  $('#copy_referral_link').on('click', function(e) {
    copyToClipboard('#copy_referral_text');
  });

  function copyToClipboard(element) {
    var $temp = $("<input>");
    $("body").append($temp);
    $temp.val($(element).val()).select();
    document.execCommand("copy");
    $temp.remove();
  }

  $(document).on('click', function (event) {
    var isInputClick = $(event.target).closest('.schedule_modal_autocomplete').length > 0;
    var isResultsItemClick = $(event.target).closest('.schedule_modal_autocomplete_results_item').length > 0;

    if (!isInputClick && !isResultsItemClick) {
      $('.schedule_modal_autocomplete_results').removeClass('show');
      $('.schedule_modal_autocomplete_results').css('display', 'none');
    }
  });

  var participantsElement = document.getElementById("other_persons");

  if (participantsElement) {
    participantsElement.addEventListener("wheel", scrollParticipants);
  }

  function scrollParticipants(e) {
    e.preventDefault();
    const container = document.getElementById("other_persons");
    const containerScrollPosition = document.getElementById("other_persons").scrollLeft;
    container.scrollTo({
      top: 0,
      left: containerScrollPosition + e.deltaY,
      behaviour: "smooth"
    });
  }

  $('.schedule_modal_autocomplete').keyup(function() {
    if ($(this).val().length > 2) {
      $.ajax({
        url: '/users/search_people',
        method: 'GET',
        data: {
          query: $(this).val()
        },
        success: function(response) {
          $('.schedule_modal_autocomplete_results').css('display', 'block');
          var resultsContainer = $('.schedule_modal_autocomplete_results');
          resultsContainer.empty();

          if (response.length > 0) {
            response.forEach(function(user, index) {
              var resultItem = $('<span class="d-flex schedule_modal_autocomplete_results_item">');
              resultItem.append(
                '<label class="schedule_modal_autocomplete_results_item_checkbox_label">' +
                  '<input type="checkbox" name="" class="schedule_modal_autocomplete_results_item_checkbox" data-checkbox="' + user.id + '">' +
                  '<span class="d-inline-block schedule_modal_autocomplete_results_item_avatar">' +
                    '<img src="' + user.picture_url + '" alt="Avatar">' +
                  '</span>' +
                  '<span class="d-inline-block schedule_modal_autocomplete_results_item_data">' +
                    '<span class="d-block schedule_modal_autocomplete_results_item_name">' +
                      '<b>' + user.first_name + " " + user.last_name + '</b>' +
                    '</span>' +
                    '<span class="d-block schedule_modal_autocomplete_results_item_email">' +
                      user.email +
                    '</span>' +
                  '</span>' +
                '</label>'
              );

              resultsContainer.append(resultItem);
            });
          } else {
            resultsContainer.html('<p>No results found.</p>');
          }

          resultsContainer.addClass('show');

          $('.schedule_modal_autocomplete_results_item_checkbox').on('change', function() {
            var idData = $(this).data('checkbox');

            var userImg = $(this).next().find('img').attr('src');
            var userName = $(this).parent().find('.schedule_modal_autocomplete_results_item_name').text();

            var cloningElement = $('.schedule_modal_autocomplete_results_input_item.clone_item');

            if(this.checked) {
              $('.schedule_modal_autocomplete_results_input_items').addClass('show');

              cloningElement.clone(true).appendTo($('.schedule_modal_autocomplete_results_input_items'));

              var clonedElement = $('.schedule_modal_autocomplete_results_input_items').find('.schedule_modal_autocomplete_results_input_item.clone_item');

              clonedElement.attr('data-checkbox', idData);
              clonedElement.find('img').attr('src', userImg);
              clonedElement.find('.schedule_modal_autocomplete_results_input_item_name').text(userName);

              clonedElement.removeClass('clone_item');
            } else {
              $('.schedule_modal_autocomplete_results_input_item[data-checkbox="' + idData + '"]').remove();

              if($('.schedule_modal_autocomplete_results_input_items').find('span').length === 0){
                $('.schedule_modal_autocomplete_results_input_items').removeClass('show');
              }
            }
          });

        },
        error: function(error) {
          console.error('Error:', error);
        }
      });
    }

    if ($(this).val().length === 0) {
      $('.schedule_modal_autocomplete_results').removeClass('show');
    }

    return false;
  });

  $('#schedule_modal_next_btn').click(function () {
    var checkboxValues = $('.schedule_modal_autocomplete_results_input_item').map(function () {
      return $(this).data('checkbox');
    }).get();

    var user_id = $(this).data('user-id');

    $.ajax({
      url: '/users/add_people_in_calendar',
      method: 'get',
      data: {
        id: user_id,
        checkboxValues: checkboxValues
      },
      success: function (response) {
        $('.post_modal_close_btn').click();
      },
      error: function (error) {
        console.error('Error:', error);
      }
    });
  });

  $('.schedule_modal_autocomplete_results_input_item_remove').on('click', function(e) {
    e.stopPropagation();
    e.preventDefault();

    var idData = $(this).parent().data('checkbox');

    $('.schedule_modal_autocomplete_results_item_checkbox[data-checkbox="' + idData + '"]').prop('checked', false);

    $(this).parent().remove();

    if($('.schedule_modal_autocomplete_results_input_items').find('span').length === 0){
      $('.schedule_modal_autocomplete_results_input_items').removeClass('show');
    }
  });

  $('.question_item_left_save_link, .event_save_link, .job_top_favorite_link, .company_medal_container, .company_medal_link, .webinar_room_action').hover(
    function() {
      $(this).next('.event_item_save_tooltip').addClass('show');
      let tooltipWidth = $(this).next('.event_item_save_tooltip').outerWidth();
      $(this).next('.event_item_save_tooltip').css('left', 'calc(50% - ' + (tooltipWidth / 2 + 3) + 'px)');
    }, function() {
      $(this).next('.event_item_save_tooltip').removeClass('show');
    }
  );

  $('.summit-schedule-calendar__sidebar-heading').on('click', function(e) {
    e.stopPropagation();
    e.preventDefault();

    $(this).toggleClass('open');
    $(this).next('.summit_schedule_calendar_categories_list').toggleClass('open');
  });

  $(".announcement_form_hidden_checkbox").on("change",function(){
    $(this).parent().parent().next('.announcement_form_hidden_textarea').toggleClass('show');
  });

  $('.editor_modal_video_upload_item').on('click', function(e) {
    e.stopPropagation();
    e.preventDefault();

    var tabData = $(this).data("tab");

    $('.editor_modal_video_upload_item_input_container').removeClass('show');
    $(this).parent().next('.editor_modal_video_upload_item_inputs_container').find('.editor_modal_video_upload_item_input_container[data-tab="' + tabData + '"]').addClass('show');

    $('.editor_modal_video_upload_item').removeClass('active');
    $(this).addClass('active');
    $(this).parent().parent().addClass('active');
  });

  $('.summit_schedule_day_month_select_item').on('click', function(e) {
    e.stopPropagation();
    e.preventDefault();

    var tabData = $(this).data("tab");

    $('.summit_schedule_calendar_item_container').removeClass('active');
    $('.summit_schedule_calendar_item_container[data-tab="' + tabData + '"]').addClass('active');

    $(this).parent().find('.summit_schedule_day_month_select_item').removeClass('active');
    $(this).addClass('active');
  });

  $('.summit_schedule_filters_search').on('click', function(e) {
    e.stopPropagation();
    e.preventDefault();

    $(this).toggleClass('show');
    $(this).next('.summit_schedule_filters_search_input_container').toggleClass('show');
  });

  $('.summit_schedule_calendar_categories_heading').on('click', function(e) {
    e.stopPropagation();
    e.preventDefault();

    $(this).toggleClass('open');
    $(this).next('.summit_schedule_calendar_categories_list').toggleClass('open');
  });

  $('.nav_content_burger_toggle').on('click', function(e) {
    e.stopPropagation();
    e.preventDefault();

    $(this).toggleClass('active');
    $(this).next('.nav_content_mobile').toggleClass('show');
    $(".content_container").toggleClass('show_overlay');
  });
  
  $('.admin_subnav_burger_toggle').on('click', function(e) {
    e.stopPropagation();
    e.preventDefault();

    $(this).toggleClass('active');
    $(this).parent().next('.admin_left_nav_container').toggleClass('show');
  });

  $('.nav_content_more_toggle.mobile_toggle').on('click', function(e) {
    e.stopPropagation();
    e.preventDefault();

    $(this).toggleClass('show');
    $(this).next('.nav_content_mobile_more_container').toggleClass('show');
  });

  $('.connected_item_close_link').on('click', function(e) {
    e.preventDefault();
    $(this).parent().removeClass('show');
  });

  // $('.connect_person_link').on('click', function(e) {
  //   e.preventDefault();
  //   $(this).parent().parent().addClass('hidden');
  //   $(this).parent().parent().next('.connected_item_container').addClass('show');
  // });

  $('.event-management-board-modal__item').on('click', function(e) {
    e.preventDefault();
    $(this).toggleClass('active');
  });

  $('.question_answers_see_all_link').on('click', function(e) {
    e.preventDefault();
    $(this).parent().toggleClass('hidden_link');
    $(this).parent().prev('.question_answers_container').toggleClass('hidden_answers');
  });

  $('.summit-schedule-calendar__toogle-item').click(function(e) {
    e.preventDefault();

    $('.summit-schedule-calendar__toogle-item').removeClass('active')

    if ($(this).hasClass('grid-view')) {
      $(this).addClass('active');
      $('.grid-view-content').addClass('active');
      $('.calendar-view-content').removeClass('active');
    }

    if ($(this).hasClass('calendar-view')) {
      $(this).addClass('active');
      $('.grid-view-content').removeClass('active');
      $('.calendar-view-content').addClass('active');
    }
  });

  $(".datepicker").each(function() {
    let format = $(this).data('format') || 'mmm d, yyyy';
    $(this).datepicker({
      uiLibrary: 'bootstrap4',
      format: format,
      footer: true,
      open: function (e) {
        console.log(e)
      }
    }).data('datepicker');
  });

  // let publicationsCount = $('#publications_carousel').find('.profile_publications_item').length;

  // $('#publications_carousel_pagination_total').text(publicationsCount);

  $('.grants_filters_item_checkboxes_item.with_subitems .grants_filters_item_checkboxes_item_trigger').on('click', function(e) {
    e.stopPropagation();
    e.preventDefault();

    $(this).toggleClass('open');
    $(this).parent().next('.grants_filters_subrows_container').toggleClass('open');
  });

  if ( $("#summits_filters_date").length ) {
    console.log('ss');
    jquery('#summits_filters_date').daterangepicker({
      startDate: moment().subtract(29, 'days'),
      endDate: moment().startOf('day'),
      locale: {
        format: 'MMM D, YYYY'
      }
    });

    jquery('#summits_filters_date').on('apply.daterangepicker', function(ev, picker) {
      $('#summits_filters_date').html(picker.startDate.format('MMM D, YYYY') + ' - <br>' + picker.endDate.format('MMM D, YYYY'));
    });
  }

  $('.user_profile_data_nav_item').click(function(e) {
    e.stopPropagation();
    e.preventDefault();
    var tabData = $(this).data("tab");

    if (typeof $(this).data('tab-parent') !== 'undefined') {
      let tabParentClass = $(this).data("tab-parent");
      $( `${tabParentClass} .user_profile_data_nav_item`).removeClass('active');
      $( `${tabParentClass} .user_profile_data_tab`).removeClass('active');
    } else {
      $('.user_profile_data_nav_item').removeClass('active');
      $('.user_profile_data_tab').removeClass('active');
    }

    $(this).addClass('active');
    $('.user_profile_data_tab[data-tab="' + tabData + '"]').addClass('active');
  });

  $('.chatGroupModal__tab-item').click(function(e) {
    e.stopPropagation();
    e.preventDefault();
    var tabData = $(this).data("tab");

    if (typeof $(this).data('tab-parent') !== 'undefined') {
      let tabParentClass = $(this).data("tab-parent");
      $( `${tabParentClass} .chatGroupModal__tab-item`).removeClass('active');
      $( `${tabParentClass} .chatGroupModal__data-tab`).removeClass('active');
    } else {
      $('.chatGroupModal__tab-item').removeClass('active');
      $('.chatGroupModal__data-tab').removeClass('active');
    }

    $(this).addClass('active');
    $('.chatGroupModal__data-tab[data-tab="' + tabData + '"]').addClass('active');
  });

  $(".group_profile_item_post_comment_file_input").on("change",function(){
    var $input = $(this);
    var reader = new FileReader();

    var file = $input[0].files[0];
    var fileType = file["type"];
    var validImageTypes = ["image/gif", "image/jpeg", "image/png"];

    var docContainer = $(this).parent().parent().parent().parent().next().next('.group_profile_comment_doc_container');

    var imgContainer = $(this).parent().parent().parent().parent().next('.group_profile_comment_img_container');

    if ($.inArray(fileType, validImageTypes) < 0) {
      imgContainer.removeClass('show');
      docContainer.addClass('show');

      docContainer.find('.group_profile_comment_doc').addClass('show');
      docContainer.find('.group_profile_comment_doc_text').text(file.name);
    } else {
      docContainer.removeClass('show');
      imgContainer.addClass('show');

      reader.onload = function(){
        imgContainer.find('.img_preview').attr("src", reader.result);
      }

      reader.readAsDataURL($input[0].files[0]);
    }
  });

  $('.group_profile_comment_img_remove.img').on('click', function(e) {
    e.stopPropagation();
    e.preventDefault();

    $(this).prev('.img_preview').attr('src','');
    $(this).parent().parent().removeClass('show');
    $(this).parent().parent().prev('.group_profile_item_post_comment_form').find('.group_profile_item_post_comment_file_input').val("");
  });

  $('.group_profile_comment_img_remove.doc').on('click', function(e) {
    e.stopPropagation();
    e.preventDefault();

    $(this).prev('.group_profile_comment_doc').removeClass('show');
    $(this).parent().parent().removeClass('show');
    $(this).parent().parent().prev().prev('.group_profile_item_post_comment_form').find('.group_profile_item_post_comment_file_input').val("");
  });

  $('.notes_toggle').click(function(e) {
    e.stopPropagation();
    e.preventDefault();

    $('.summit_application_notes_container').toggleClass('show');
    $('.job-management-board__box').toggleClass('notes_open');
  });

  $(document).ajaxComplete(function () {
    $('input.emojionearea, input.emoji-icon, textarea.emojionearea, textarea.emoji-icon').each(function () {
      jquery(this).emojioneArea({
        pickerPosition: "top"
      });
    });
  });

  $('input.emojionearea, input.emoji-icon, textarea.emojionearea, textarea.emoji-icon').each(function () {
    if ($('.emojionearea.emoji-icon').length == 0){
      jquery(this).emojioneArea({
        pickerPosition: "top"
      });
    }
  });

  if ( $("#rss_daterange_input").length ) {
    jquery('#rss_daterange_input').daterangepicker({
      startDate: moment().subtract(29, 'days'),
      endDate: moment().startOf('day'),
      locale: {
        format: 'MMM D, YYYY'
      }
    });
  }

  if ( $("#rss_daterange_input").length ) {
    let daterangepickerModule = jquery('#rss_daterange_input').data('module') || 'events'
    jquery('#rss_daterange_input').daterangepicker({
      autoUpdateInput: true,
      locale: {
        format: 'MMM D, YYYY'
      }
    }).on('cancel.daterangepicker', function() {
      $(this).val('');
      sendFilterRequest();
    }).val('').change(sendFilterRequest);

    function sendFilterRequest() {
          let search = $('#searchNews').val();
          let date = $('#rss_daterange_input').val();
          let categoryText = $("#dropdownsearchMenuButton1").text();
          let category = categoryText.trim() === "Select Category" ? null : categoryText;
          $('#news_listing_container').load('news/filter', {
              search,
              date,
              category
          });
    };
  }

  $('.dropdown-submenu a.dropdown-toggle').on('click', function(e) {
    e.stopPropagation();
    e.preventDefault();

    if (!$(this).next().hasClass('show')) {
      $(this).parents('.dropdown-menu').first().find('.show').removeClass("show");
    }

    var $subMenu = $(this).next(".dropdown-menu");
    $subMenu.toggleClass('show');
    $(this).parents('li.nav-item.dropdown.show').on('hidden.bs.dropdown', function(e) {
      $('.dropdown-submenu .show').removeClass("show");
    });
    return false;
  });

  $('.user_profile_progress_toggle').click(function(e) {
    e.stopPropagation();
    e.preventDefault();

    $(this).parent().toggleClass('collapsed');
  });

  $('.question_answers_item_accepted_mark').click(function(e) {
    e.stopPropagation();
    e.preventDefault();

    var answerTooltip = $(this).next('.question_answers_item_accepted_tooltip');

    if (answerTooltip.hasClass('show')) {
      answerTooltip.removeClass('show');
    } else {
      answerTooltip.addClass('show');
    }
  });

  $('.question_answers_item_accepted_mark').hover(
    function() {
      $(this).next('.question_answers_item_accepted_tooltip').addClass('show');
    }, function() {
      $(this).next('.question_answers_item_accepted_tooltip').removeClass('show');
    }
  );

  $('.activity_feed_post_form_question_details_btn').click(function(e) {
    e.stopPropagation();
    e.preventDefault();

    $(this).addClass('hide');
    $(this).parent().prev('.activity_feed_post_form_question_textarea_container').addClass('show');
  });

  $('.question_answers_item_text_see_more').click(function(e) {
    e.stopPropagation();
    e.preventDefault();

    $(this).parent().addClass('hidden');
    $(this).parent().next('.question_answers_item_text_full').addClass('show');
  });

  $('.summit_timeline_modal_schedule_item_delete').click(function(e) {
    e.stopPropagation();
    e.preventDefault();

    $(this).parent().parent().remove();
  });

  $('.summit_timeline_modal_schedule_btn').click(function() {
    if ($(this).hasClass('active')) {
      var cloningElement = $(this).parent().parent().find('.summit_timeline_modal_schedule_item.active.for_cloning');
    } else if ($(this).hasClass('inactive')) {
      var cloningElement = $(this).parent().parent().find('.summit_timeline_modal_schedule_item.inactive.for_cloning');
    }

    cloningElement.clone(true).appendTo($(this).parent().parent().find('.summit_timeline_modal_schedule_items_container'));

    var clonedElement = $(this).parent().parent().find('.summit_timeline_modal_schedule_items_container .summit_timeline_modal_schedule_item.for_cloning');

    clonedElement.removeClass('for_cloning');

    clonedElement.find('.summit_timeline_modal_schedule_item_time_select').selectpicker();
  });

  $('.add_another_panelist').click(function() {
    var panelistCloningElement = $(this).parent().parent().find('.summit_timeline_modal_schedule_item.panelist.for_cloning');

    panelistCloningElement.clone(true).appendTo($(this).parent().parent().find('.summit_timeline_modal_schedule_items_container'));

    var panelistClonedElement = $(this).parent().parent().find('.summit_timeline_modal_schedule_items_container .summit_timeline_modal_schedule_item.for_cloning');

    panelistClonedElement.removeClass('for_cloning');

    panelistClonedElement.find('.summit_timeline_modal_schedule_item_time_select').selectpicker();
  });

  $('.job_search_left_filters_btn.additional').click(function(e) {
    e.stopPropagation();
    e.preventDefault();

    $('.job_search_left_filters_block.more_filters').addClass('show');
  });

  $('.company_profile_paper_month_close').click(function(e) {
    e.stopPropagation();
    e.preventDefault();

    $('.company_profile_paper_month_container').addClass('hidden');
  });

  $('.profile_right_feed_nav_item').click(function(e) {
    e.stopPropagation();
    e.preventDefault();

    var tabData = $(this).data("tab");

    $(this).parent().find('.profile_right_feed_nav_item').removeClass('active');
    $(this).addClass('active');

    $('.profile_right_feed_tab').removeClass('active');
    $('.profile_right_feed_tab[data-tab="' + tabData + '"]').addClass('active');
  });

  $('.event_agenda_filters_nav_item').click(function(e) {
    e.stopPropagation();
    e.preventDefault();

    var tabData = $(this).data("tab");

    $(this).parent().find('.event_agenda_filters_nav_item').removeClass('active');
    $(this).addClass('active');

    $('.event_agenda_filters_checkboxes_container').removeClass('active');
    $('.event_agenda_filters_checkboxes_container[data-tab="' + tabData + '"]').addClass('active');
  });

  $('.admin_main_tab_link').click(function(e) {
    if(!$(this).parent().hasClass('cs_users_types')) {
      e.stopPropagation();
      e.preventDefault();

      var tabData = $(this).data("tab");

      $(this).parent().find('.admin_main_tab_link').removeClass('active');
      $(this).addClass('active');

      $('.admin_table_container').removeClass('active');
      $('.admin_table_container[data-tab="' + tabData + '"]').addClass('active');
    }
  });

  $('.admin_main_tab_link_events').click(function(e) {
    if(!$(this).parent().hasClass('cs_users_types')) {
      e.stopPropagation();
      // e.preventDefault();

      var tabData = $(this).data("tab");

      $(this).parent().find('.admin_main_tab_link_events').removeClass('active');
      $(this).addClass('active');

      $('.admin_table_container').removeClass('active');
      $('.admin_table_container[data-tab="' + tabData + '"]').addClass('active');
    }
  });

  $(document).on('click', '.user_dashboard_tabs_toggle_item', function(e) {
    e.stopPropagation();
    e.preventDefault();

    var tabData = $(this).data("tab");

    $(this).parent().find('.user_dashboard_tabs_toggle_item').removeClass('active');
    $(this).addClass('active');

    $(this).parent().parent().parent().find('.user_dashboard_tabs_content').removeClass('active');
    $('.user_dashboard_tabs_content[data-tab="' + tabData + '"]').addClass('active');
  });

  $('body').on('click', '.messages-center__chats-toggle-item', function(e) {
    e.stopPropagation();
    e.preventDefault();
    var tabData = $(this).data("tab");

    $('.messages-center__chats-toggle-item').removeClass('active');
    $(this).addClass('active');
    $('.messages_left_part_container').removeClass('active');
    $('.messages_left_part_container[data-tab="' + tabData + '"]').addClass('active');
  });

  $('body').on('click', '.messages-center__group-title-link', function(e) {
    e.stopPropagation();
    e.preventDefault();

    $(this).parent().parent().toggleClass('closed');
  });

  $('.announcements_notification_close, .announcements_content_item_dismiss_link').on('click', function() {
    var announcementId = $(this).data("announcement-id");

    $('.announcements_notification_container[data-announcement-id="' + announcementId + '"]').removeClass('show');
  });

  $(document).on('click', '.group_profile_content_item_reply_link', function(e) {
    e.preventDefault();

    $(this).parent().next('.reply_comment_form').toggleClass('show');
  });

  $(document).on('click', '.group_profile_content_item_comments_item_more_replies_link_text', function(e) {
    e.preventDefault();

    $(this).toggleClass('show');
    $(this).parent().next('.group_profile_content_item_comments_item_more_replies_container').toggleClass('show');

    if ($(this).hasClass('show')) {
      $(this).text('Show less replies');
    } else {
      $(this).text('Show more replies');
    }
  });

  $('.company_profile_content_right_bottom_link.more_details').click(function(e) {
    e.preventDefault();

    $(this).toggleClass('open');
    $(this).parent().parent().find('.company_about_more_content').toggleClass('open');

    if ($(this).hasClass('details')) {
      if($(this).hasClass('open')) {
        $(this).text('View Less Details');
      } else {
        $(this).text('View More Details');
      }
    } else if ($(this).hasClass('companies')) {
      if($(this).hasClass('open')) {
        $(this).text('View less similar companies');
      } else {
        $(this).text('View all similar companies');
      }
    } else if ($(this).hasClass('connections')) {
      if($(this).hasClass('open')) {
        $(this).text('View less connections');
      } else {
        $(this).text('View all connections');
      }
    } else if ($(this).hasClass('research-centers')) {
      if($(this).hasClass('open')) {
        $(this).text('View fewer research centers');
      } else {
        $(this).text('View all similar research centers');
      }
    }
  });

  $('#primary-menu-toggle').click(function(e) {
    e.preventDefault();

    $('#primary-menu').toggleClass('nav-menu--minimized');
  });

  $('#primary-menu-more').click(function(e) {
    e.preventDefault();

    $('#primary-menu').toggleClass('nav-menu--full');
  });

  $('#affiliation_other').change(function() {
    var parentContainer = $(this).parent().parent();

    if(this.checked) {
      $(parentContainer).addClass('show_form');
    } else {
      $(parentContainer).removeClass('show_form');
    }
  });

  $('#job-management-board-check_all').change(function() {
    var checkBoxes = $('.job-management-board-checkbox');

    if ($(this).prop('checked')) {
      checkBoxes.prop('checked', true);
    } else {
      checkBoxes.prop('checked', false);
    }
  });

  $('.create_job_form_remote_checkbox').click(function() {
    if ($(this).prop('checked')) {
      $('.create_job_form_location_input').prop('disabled', true);
      $('.create_job_form_hybrid_checkbox').prop('checked', false);
    } else {
      $('.create_job_form_location_input').prop('disabled', false);
    }
  });

  $('.job_management_board_filter_role_checkbox').change(function() {
    $('.job_management_board_filter_role_dropdown_text').text($(this).val());
    $('.job_management_board_filter_role_dropdown_text').removeClass('member').removeClass('admin');

    if ($(this).val() == 'Member') {
      $('.job_management_board_filter_role_dropdown_text').addClass('member');
    } else if ($(this).val() == 'Admin') {
      $('.job_management_board_filter_role_dropdown_text').addClass('admin');
    }
  });

  $('.create_job_form_hybrid_checkbox').click(function() {
    if ($(this).prop('checked')) {
      $('.create_job_form_location_input').prop('disabled', false);
      $('.create_job_form_remote_checkbox').prop('checked', false);
    } else {
      $('.create_job_form_location_input').prop('disabled', false);
    }
  });

  $('.create_job_form_add_input_btn').on('click', function() {
    let type = $(this).data('type') || 'generic';
    var multipleInputsContainer = $(this).parent().parent().find('.create_job_form_row_right_multiple_inputs_container');
    if (type == 'company') {
      var index = $(`[name*="${type}[address]["][type="hidden"]`).length
      var inputToClone = `<select name="${type}[address][${index}]" placeholder="Headquarters" class="create_job_form_input mt-2 hq"></select>`
    } else {
      var inputToClone = $(this).parent().parent().find('.create_job_form_input_container').find("input").first().clone();
      inputToClone.val("");
      inputToClone.addClass("mt-2");
    }
    multipleInputsContainer.append(inputToClone);
    if(typeof initAutocomplete == 'function') {
      initAutocomplete()
    }
  });


  $('.job_search_left_filters_heading').on('click', function() {
    $(this).toggleClass('show');
    $(this).next('.job_search_left_filters_block_checkboxes_container').toggleClass('show');
  });

  $('.timepicker').each(function() {
    let format = $(this).data('timepicker-format') || 'MM:HH'
    $(this).timepicker({ format });
  });

  $('.rss_feeds_filters_toggle').on('click', function() {
    $(this).next('.rss_feeds_filters_dropdown').toggleClass('show');
  });

  $('.users_search_filters_item_dropdown').on("click.bs.dropdown", function (e) {
    e.stopPropagation();
  });

  $('.user_dashboard_research_item_top').click(function() {
    console.log('ss');
    $(this).toggleClass('open');
    $(this).next('.user_dashboard_research_inner_content').toggleClass('show');
  });

  $('.grant_dropdown_trigger').click(function() {
    $(this).toggleClass('open');
    $(this).next('.grant_dropdown_content_container').toggleClass('show');
  });

  $('.profile_big_heading.with_dropdown').click(function() {
    $(this).toggleClass('open');
    $(this).next('.profile_dropdown_content_container').toggleClass('show');
  });

  $('.profile_right_feed_heading.with_dropdown').click(function() {
    $(this).toggleClass('open');
    $(this).prev('.profile_right_feed_nav.with_dropdown').toggleClass('open');
    $(this).next('.profile_dropdown_content_container').toggleClass('show');
    $(this).parent('').toggleClass('opened');
  });

  $('.management_board_link').click(function(e) {
    e.stopPropagation();
    e.preventDefault();

    var tabData = $(this).data("tab");

    $('.management_board_link').removeClass('active');
    $('.management_board_item').removeClass('active');

    $('.management_board_link[data-tab="' + tabData + '"]').addClass('active');
    $('.management_board_item[data-tab="' + tabData + '"]').addClass('active');
  });

  $('.events_nav_item').click(function(e) {
    e.stopPropagation();
    e.preventDefault();

    var tabData = $(this).data("tab");

    $('.events_nav_item').removeClass('active');
    $('.rss_feeds_item.events_item').removeClass('active');
    $('.rss_feeds_item.events_item').removeClass('first_item');

    if (tabData === 'discover') {
      $('.events_nav_item[data-tab="' + tabData + '"]').addClass('active');
      $('.rss_feeds_item.events_item').addClass('active');
      $('.rss_feeds_item.events_item.active:first').addClass('first_item');
    } else {
      $('.events_nav_item[data-tab="' + tabData + '"]').addClass('active');
      $('.rss_feeds_item.events_item[data-tab="' + tabData + '"]').addClass('active');
      $('.rss_feeds_item.events_item.active[data-tab="' + tabData + '"]:first').addClass('first_item');
    }
  });

  $('.company_profile_content_left_item_bottom_nav_item').click(function(e) {
    e.stopPropagation();
    e.preventDefault();

    var tabData = $(this).data("tab");

    $('.company_profile_content_left_item_bottom_nav_item').removeClass('active');
    $('.company_profile_content_left_item, .student-profile__connections').removeClass('active');

    if (tabData === 'overview') {
      $('.company_profile_content_left_item_bottom_nav_item[data-tab="' + tabData + '"]').addClass('active');
      $('.company_profile_content_left_item:not(.events)').addClass('active show_all');
    } else {
      $('.company_profile_content_left_item_bottom_nav_item[data-tab="' + tabData + '"]').addClass('active');
      $('.company_profile_content_left_item[data-tab="' + tabData + '"], .student-profile__connections[data-tab="' + tabData + '"]').addClass('active');
    }
  });

  $('.user_profile_form_nav_item').click(function(e) {
    e.stopPropagation();
    e.preventDefault();
    if ($('.validate_school_name').length > 0 && $('#school_name').val() == ''){
      $('.validate_school_name').removeClass('hidden');
    }else {
      $('.validate_school_name').addClass('hidden');
      var tabData = $(this).data("tab");
      $('.user_profile_form_nav_item').removeClass('active');
      $('.create_job_tab_container').removeClass('active');

      $('.user_profile_form_nav_item[data-tab="' + tabData + '"]').addClass('active');
      $('.create_job_tab_container[data-tab="' + tabData + '"]').addClass('active');
    }
  });

  $('.create_job_step_item').click(function(e) {
    e.stopPropagation();
    e.preventDefault();

    var tabData = $(this).data("tab");

    $('.create_job_step_item').removeClass('active');
    $('.create_job_tab_container').removeClass('active');

    $('.create_job_step_item[data-tab="' + tabData + '"]').addClass('active');
    $('.create_job_tab_container[data-tab="' + tabData + '"]').addClass('active');

    if ( $(this).hasClass('media_tab') ) {
      $('.create_job_step_item.announcement_info_tab').addClass('active');
    }

    if ( $(this).hasClass('recipients_tab') ) {
      $('.create_job_step_item.announcement_info_tab').addClass('active');
      $('.create_job_step_item.media_tab').addClass('active');
    }
  });

  $('.user_dashboard_nav_item').click(function(e) {
    e.stopPropagation();
    e.preventDefault();

    var tabData = $(this).data("tab");

    $('.user_dashboard_nav_item').removeClass('active');
    $('.user_dashboard_right_inner').removeClass('active');

    $('.user_dashboard_nav_item[data-tab="' + tabData + '"]').addClass('active');
    $('.user_dashboard_right_inner[data-tab="' + tabData + '"]').addClass('active');
  });

  $('.post_modal_bottom_link').click(function(e) {
    e.stopPropagation();
    e.preventDefault();
    var tabData = $(this).data("tab");

    if (tabData === 'photo') {
      $('.post_modal_heading_text').text('Select/Edit your photo');
    } else if (tabData === 'video') {
      $('.post_modal_heading_text').text('Select/Edit your video');
    } else if (tabData === 'document') {
      $('.post_modal_heading_text').text('Share a document');
    }

    $('.post_modal_author_container').removeClass('show');
    $('.post_modal_text_container').removeClass('show');
    $('.post_modal_bottom_container').removeClass('show');

    $('.post_modal_upload_container[data-tab="' + tabData + '"]').addClass('show');
    $('.post_modal_upload_cloud_container[data-tab="' + tabData + '"]').addClass('show');
    $('.post_modal_bottom_container.upload').addClass('show');
  });

  $('.post_modal_bottom_back_link').click(function(e) {
    e.stopPropagation();
    e.preventDefault();

    if ($('#post_video').is(':visible')) {
      $('#post_video').wrap('<form>').closest('form').get(0).reset()
      $('#post_video').unwrap()
      $('#InputYoutubeLink').val('')
      $('#InputVimeoLink').val('')
    }

    $('.post_modal_heading_text').text('Create a post');

    $('.post_modal_upload_container').removeClass('show');
    $('.post_modal_upload_cloud_container').removeClass('show');
    $('.post_modal_bottom_container').removeClass('show');

    $('.post_modal_author_container').addClass('show');
    $('.post_modal_text_container').addClass('show');
    $('.post_modal_bottom_container.initial').addClass('show');
  });

  $('.post_modal_bottom_submit_btn.upload').click(function(e) {
    e.stopPropagation();
    e.preventDefault();

    if ($('#post_video').is(':visible')) {
      let uploadedVideo = $('#post_video')[0].files.length
      let youtubeLink = $('#InputYoutubeLink').val().trim()
      let vimeoLink = $('#InputVimeoLink').val().trim()
      let checker = []
      if (uploadedVideo) {
        checker.push(1)
      }
      if (youtubeLink) {
        checker.push(2)
      }
      if (vimeoLink) {
        checker.push(3)
      }

      if (checker.length > 1) {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: "Please select only one upload option.",
          showConfirmButton: false,
          timer: 3000
        })
        return false
      }
    }

    $('.post_modal_heading_text').text('Create a post');

    $('.post_modal_upload_container').removeClass('show');
    $('.post_modal_upload_cloud_container').removeClass('show');
    $('.post_modal_bottom_container').removeClass('show');

    $('.post_modal_author_container').addClass('show');
    $('.post_modal_text_container').addClass('show');
    $('.post_modal_bottom_container.initial').addClass('show');
  });

  $('.company_profile_tabs_nav_item').click(function(e) {
    e.stopPropagation();
    e.preventDefault();
    var tabData = $(this).data("tab");
    $('.company_profile_tabs_nav_item').removeClass('active');
    $(this).addClass('active');
    $('.company_profile_tab').removeClass('active');
    $('.company_profile_tab[data-tab="' + tabData + '"]').addClass('active');
  });

  $('.copy_button_email').click(function(e) {
    e.stopPropagation();
    e.preventDefault();

    var textForCopy = $(this).text();

    navigator.clipboard.writeText(textForCopy);
    $('.copy_text_email').removeClass('d-none')
    $('.copy_email_image').addClass('d-none')
    $('.copy_text_user').addClass('d-none')
    $('.copy_user_image').removeClass('d-none')
  });

  $('.copy_button_user').click(function(e) {

    e.stopPropagation();
    e.preventDefault();

    var textForCopy = $(this).text();

    navigator.clipboard.writeText(textForCopy);
    $('.copy_text_user').removeClass('d-none')
    $('.copy_user_image').addClass('d-none')
    $('.copy_text_email').addClass('d-none')
    $('.copy_email_image').removeClass('d-none')
  });

  $('.activity_feed_top_nav_toggle_item').click(function(e) {
    e.stopPropagation();
    e.preventDefault();
    var tabData = $(this).data("tab");

    $('.activity_feed_top_nav_toggle_item').removeClass('active');
    $(this).addClass('active');

    if (tabData === "all") {
      $('.group_profile_item.activity').removeClass('hidden');
    } else {
      $('.group_profile_item.activity').addClass('hidden');
      $('.group_profile_item.activity[data-tab="' + tabData + '"]').removeClass('hidden');
    }

    if (tabData === "questions") {
      $('#post-text').attr("placeholder", "Ask a question");
      $('#post-text').text("Ask a question");
      $('#post-text').attr("data-target", "#questionModal");
      $('.activity_feed_post_form_question_container').addClass('show');
      $('.activity_feed_questions_container').addClass('show');
    } else {
      $('#post-text').attr("placeholder", "Start a post");
      $('#post-text').text("Start a post");
      $('#post-text').attr("data-target", "#newPostModal");
      $('.activity_feed_post_form_question_container').removeClass('show');
    }
  });

  $('.groups_search_layout_toggle_item').click(function() {
    var tabData = $(this).data("tab");
    sessionStorage.setItem("view", tabData);
    $('.groups_search_layout_toggle_item').removeClass('active');
    $(this).addClass('active');
    $('.groups_search_results_items_container').removeClass('rows');
    $('.groups_search_results_items_container').removeClass('grid');
    $('.groups_search_results_items_container').addClass(tabData);
  });

  if ($('#event_content_container').length > 0) {
    var heightThreshold = $('#event_content_container').offset().top;
  }

  if ($('#company_top_data_container').length > 0) {
    var companyHeightThreshold = $('#company_top_data_container').offset().top;
  }

  $(window).scroll(function() {
    var scroll = $(window).scrollTop();

    // if ($('#event_content_container').length > 0) {
    //   if (scroll >= heightThreshold) {
    //     $('#event_top_container').addClass('on_scroll');
    //   } else {
    //     $('#event_top_container').removeClass('on_scroll');
    //   }
    // }

    if ($('#company_top_data_container').length > 0) {
      if (scroll >= companyHeightThreshold) {
        $('#company_top_scroll_container').addClass('on_scroll');
      } else {
        $('#company_top_scroll_container').removeClass('on_scroll');
      }
    }
  });

  $(window).on("load", function() {
    console.log(sessionStorage.getItem("view"));
    if (sessionStorage.getItem("view") === "rows"){
      $('.list_view').addClass('active');
      $('.grid_view').removeClass('active');
      $('.groups_search_results_items_container').removeClass('grid');
      $('.groups_search_results_items_container').addClass('rows');
    }
    else if (sessionStorage.getItem("view") === "grid") {
      $('.list_view').removeClass('active');
      $('#grid-id').addClass('active');
      $('.grid_view').addClass('active');
      $('.groups_search_layout_toggle_item.rows').removeClass('active');
      $('.groups_search_layout_toggle_item.grid').addClass('active');
      $('.groups_search_results_items_container').removeClass('rows');
      $('.groups_search_results_items_container').addClass('grid');
    }
  });

  $('.account_setup_form_show_password').click(function() {
    var prevInput = $(this).prev('.account_setup_form_input.password');

    if ( prevInput.hasClass('show') ) {
      prevInput.removeClass('show');
      prevInput.attr('type', 'password');
    } else {
      prevInput.addClass('show');
      prevInput.attr('type', 'text');
    }
  });

  $('.nav-link').on('click', function() {
    $('#navbarSupportedContent a.active').removeClass('active');
    $(this).addClass('active');
  });
  if ( $("#create_job_salary_min").length ) {
    var lowerSlider = document.querySelector('#create_job_salary_min'); //Lower value slider
    var upperSlider = document.querySelector('#create_job_salary_max'); //Upper value slider

    var lowerVal = parseInt(lowerSlider.value); //Value of lower slider
    var upperVal = parseInt(upperSlider.value); // Value of upper slider

    var rangeColor = document.querySelector('#create_job_salary_range_color'); //Range color

    var lowerSliderMax = document.querySelector('#create_job_salary_min').max;
    var upperSliderMax = document.querySelector('#create_job_salary_max').max;

    var lowerSliderMaxVal = (lowerSliderMax/100);
    var upperSliderMaxVal = (upperSliderMax/100);

    rangeColor.style.marginLeft = (lowerSlider.value)/lowerSliderMaxVal + '%';
    rangeColor.style.width = (upperSlider.value/upperSliderMaxVal) - (lowerSlider.value/lowerSliderMaxVal) + '%';

    $("#create_job_salary_min_number").val(lowerSlider.value);
    $("#create_job_salary_max_number").val(upperSlider.value);

    //When the upper value slider is moved.
    upperSlider.oninput = function() {
       lowerVal = parseInt(lowerSlider.value); //Get lower slider value
       upperVal = parseInt(upperSlider.value); //Get upper slider value



      var lowerSliderMax = document.querySelector('#create_job_salary_min').max;
      var upperSliderMax = document.querySelector('#create_job_salary_max').max;

      var lowerSliderMaxVal = (lowerSliderMax/100);
      var upperSliderMaxVal = (upperSliderMax/100);

      rangeColor.style.marginLeft = (lowerSlider.value)/lowerSliderMaxVal + '%';
      rangeColor.style.width = (upperSlider.value/upperSliderMaxVal) - (lowerSlider.value/lowerSliderMaxVal) + '%';


       //If the upper value slider is LESS THAN the lower value slider plus one.
       if (upperVal < lowerVal ) {
          //The lower slider value is set to equal the upper value slider minus one.
          lowerSlider.value = upperVal ;

          $("#create_job_salary_min_number").val(lowerSlider.value);

          //If the lower value slider equals its set minimum.
          if (lowerVal === lowerSlider.min) {
             //Set the upper slider value to equal 1.
             upperSlider.value = 1;

             $("#create_job_salary_max_number").val(upperSlider.value);
          }
       }


       //Setting the margin left of the middle range color.
       //Taking the value of the lower value and then turning it into a percentage.
       rangeColor.style.marginLeft = (lowerSlider.value/lowerSliderMaxVal) + '%';

       //Setting the width of the middle range color.
       //Taking the value of the upper value and subtracting the lower value and then turning it into a percentage.
       rangeColor.style.width = (upperSlider.value/upperSliderMaxVal) - (lowerSlider.value/lowerSliderMaxVal) + '%';

       $("#create_job_salary_max_number").val(upperSlider.value);
    };

    //When the lower value slider is moved.
    lowerSlider.oninput = function() {
       lowerVal = parseInt(lowerSlider.value); //Get lower slider value
       upperVal = parseInt(upperSlider.value); //Get upper slider value

      var lowerSliderMax = document.querySelector('#create_job_salary_min').max;
      var upperSliderMax = document.querySelector('#create_job_salary_max').max;

      var lowerSliderMaxVal = (lowerSliderMax/100);
      var upperSliderMaxVal = (upperSliderMax/100);

      rangeColor.style.marginLeft = (lowerSlider.value)/lowerSliderMaxVal + '%';
      rangeColor.style.width = (upperSlider.value/upperSliderMaxVal) - (lowerSlider.value/lowerSliderMaxVal) + '%';


       //If the lower value slider is GREATER THAN the upper value slider minus one.
       if (lowerVal > upperVal ) {
          //The upper slider value is set to equal the lower value slider plus one.
          upperSlider.value = lowerVal ;

          $("#create_job_salary_max_number").val(upperSlider.value);

          //If the upper value slider equals its set maximum.
          if (upperVal === upperSlider.max) {
             //Set the lower slider value to equal the upper value slider's maximum value minus one.
             lowerSlider.value = parseInt(upperSlider.max) ;

             $("#create_job_salary_min_number").val(lowerSlider.value);
          }

       }

       //Setting the margin left of the middle range color.
       //Taking the value of the lower value and then turning it into a percentage.
       rangeColor.style.marginLeft = (lowerSlider.value/lowerSliderMaxVal) + '%';

       //Setting the width of the middle range color.
       //Taking the value of the upper value and subtracting the lower value and then turning it into a percentage.
       rangeColor.style.width = (upperSlider.value/upperSliderMaxVal) - (lowerSlider.value/lowerSliderMaxVal) + '%';

       $("#create_job_salary_min_number").val(lowerSlider.value);
    };
  }

  const months = [
    { 'id': 1, 'name': 'Jan' },
    { 'id': 2, 'name': 'Feb' },
    { 'id': 3, 'name': 'Mar' },
    { 'id': 4, 'name': 'Apr' },
    { 'id': 5, 'name': 'May' },
    { 'id': 6, 'name': 'Jun' },
    { 'id': 7, 'name': 'Jul' },
    { 'id': 8, 'name': 'Aug' },
    { 'id': 9, 'name': 'Sep' },
    { 'id': 10, 'name': 'Oct' },
    { 'id': 11, 'name': 'Nov' },
    { 'id': 12, 'name': 'Dec' },
  ];
  var currentYear = new Date().getFullYear();
  var currentMonth = new Date().getMonth() + 1;


  function letsCheck(year, month) {
    var daysInMonth = new Date(year, month, 0).getDate();
    var firstDay = new Date(year, month, 0).getUTCDay();
    var array = {
        daysInMonth: daysInMonth,
        firstDay: firstDay
    };
    return array;
  }

  function makeCalendar(year, month) {
    var getChek = letsCheck(year, month);
    getChek.firstDay === 0 ? getChek.firstDay = 7 : getChek.firstDay;
    $('#calendarList').empty();
    for (let i = 1; i <= getChek.daysInMonth; i++) {
      if (i === 1) {
        var div = '<li id="' + i + '" style="grid-column-start: ' + getChek.firstDay + ';">1</li>';
      } else {
        var div = '<li id="' + i + '" >' + i + '</li>'
      }
      $('#calendarList').append(div);
    }
    monthName = months.find(x => x.id === month).name;
    $('#yearMonth').text(monthName + ' ' + year);
  }

  makeCalendar(currentYear, currentMonth);

  $('.prevMonth_btn').click(function() {
    prevMonth();
  });

  $('.nextMonth_btn').click(function() {
    nextMonth();
  });

  function nextMonth() {
    currentMonth = currentMonth + 1;
    if (currentMonth > 12) {
      currentYear = currentYear + 1;
      currentMonth = 1;
    }
    $('#calendarList').empty();
    $('#yearMonth').text(currentYear + ' ' + currentMonth);
    makeCalendar(currentYear, currentMonth);
  }

  function prevMonth() {
    currentMonth = currentMonth - 1;
    if (currentMonth < 1) {
      currentYear = currentYear - 1;
      currentMonth = 12;
    }
    $('#calendarList').empty();
    $('#yearMonth').text(currentYear + ' ' + currentMonth);
    makeCalendar(currentYear, currentMonth);
  }

  $('body').on('click', function(e) {
    var summit_calendar_search = $(".summit_schedule_filters_search_input_container");

    if (!summit_calendar_search.is(e.target) && summit_calendar_search.has(e.target).length === 0) {
      summit_calendar_search.removeClass('show');
      $('.summit_schedule_filters_search').addClass('show');
      $('.calendar_search_b').addClass('show');
    }
  });
});

(function () {
  $.fn.fancyMultiSelect = function(options) {
      var settings = $.extend({initValues: []}, options );
      var context = this;
      $(context).on('change', function() {
        $('.select_name_item', context).remove();

        $(':selected', context).each(function(){
          var text = $(this).text();
          var value = $(this).val();

          $('.filter-option-inner', this).prepend("<span class='select_name_item' data-id='"+value+"'>"+text+"++++</span>");
        });

        var selectValue = $(context).val();

        if (selectValue.length === 0) {
          $('.dropdown-toggle .filter-option-inner-inner', this).show().text('Please select');
        } else {
          $('.dropdown-toggle .filter-option-inner-inner', this).hide();
        }
      });

      $('.select_name_item', context).on('click', function() {
        var id = $(this).data("id");
        $(this).remove();

        $(':selected', context).each(function() {
          if($(this).val() === id){
           $("select option[value='"+id+"']", context).prop("selected", false);
          }
        });
      });

      $('.filter-option-inner-inner', context).addClass('hidden');
      for (let index = 0; index < settings.initValues.length; index++) {
        const element = settings.initValues[index];
        $('.dropdown-toggle .filter-option-inner', context).prepend("<span class='select_name_item' data-id='"+element.id+"'>"+ element.name+ "***</span>");
      }
  };
}());
