$(document).on('turbolinks:load', function() {
    $('#webinar_management_attendee_search').keyup(e => {
        let slug = $(e.currentTarget).data('slug')
        filterWebinarManagementAttendees(slug)
    })

    $('#webinar_management_attendee_role').change(e => {
        let slug = $(e.currentTarget).data('slug')
        filterWebinarManagementAttendees(slug)
    })

    function filterWebinarManagementAttendees(slug) {
        if (!slug) return false
        let authenticity_token = $('meta[name=csrf-token]').attr('content')
        let role = $('#webinar_management_attendee_role').val()
        let search = $('#webinar_management_attendee_search').val()

        $('#management-attendees-here').load(`/webinars/${slug}/management/filter`, { authenticity_token, role, search }, () => {
            $('.selectpicker').selectpicker()
        })
    }
})