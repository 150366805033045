$(document).on("turbolinks:load",()=>{

  var manager_user = {};

 $('body').on('click', '.user_status', function(){
   $('.update_users').removeClass('d-none');
    var chek = document.getElementsByClassName($(this).data('managerUserId')+"_user");
    for (var i = 0; i < chek.length; i++) {
      chek.item(i).checked = false;
    }
    document.getElementById($(this).attr('id')).checked = true;
    manager_user[$(this).data('managerUserId')] = $(this).data('status');
 });

 $('body').on('click', '.update_users', function(){
    $.ajax({
      url: '/admin/manager_users/update_users',
      type: 'POST',
      data: {authenticity_token: $('[name="csrf-token"]')[0].content, id: $(this).data('id'), status: manager_user, type: $(this).data('type')}
    }) ;
 });
});


