$(document).on('turbolinks:load', function() {
  $('body').on('change', '.permission', (e)=>{
    var th = $(e.target);
    $.ajax({
      url: '/admin/'+ th.data('type') +'/'+ th.data('id') +'/permission_change',
      type: 'GET',
      data: {permission: th.val()},
      dataType: 'script',
      success:function(response){
        th.val() == 'true' ? th.val('false') : th.val('true')
      }
    })
  });
  $('body').on('change', '#all_job_members', (e)=>{
    var th = $(e.target);
    $.ajax({
      url: '/'+ th.data('controller') +'/'+th.data('id')+'/dashboard',
      dataType: 'script',
      type: 'GET',
      data: {search: th.val(), type: th.data('type')}
    })
  });

  $('body').on('change', '#user_type_select', (e)=>{
    var th = $(e.target);
    $.ajax({
      url: '/'+ th.data('controller') +'/'+th.data('id')+'/dashboard',
      dataType: 'script',
      type: 'GET',
      data: {type: th.val(), search: th.data('search')}
    })
  });

  $('body').on('click', '.all_members_pagination', (e)=>{
    var th = $(e.target);
    $.ajax({
      url: '/'+ th.data('controller') +'/'+th.data('id')+'/dashboard',
      dataType: 'script',
      type: 'GET',
      data: {type: th.data('type'), search: th.data('search'), page: th.data('page')}
    })
  });
});

