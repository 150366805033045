$(document).on('turbolinks:load', function() {
	$(document).on('click', '.report_user', function(event){
		event.preventDefault();
		var reported_user_id = $(this).attr('id');
		$.ajax({
			url: '/report_user/',
			type: "POST",
			data: {reported_user: {reported_user_id: reported_user_id, reason_for_report: $('#reason_' + reported_user_id).val(), description_of_offense: $('#description_' + reported_user_id).val()} }
		});
	});

	$(document).on('click', '.report_empty_chat_user', function(event){
		event.preventDefault();
		var reported_user_id = $(this).attr('id');
		$.ajax({
			url: '/report_user/',
			type: "POST",
			data: {reported_user: {reported_user_id: reported_user_id, reason_for_report: $('#user_reason_' + reported_user_id).val(), description_of_offense: $('#user_description_' + reported_user_id).val()} }
		});
	});

	$(document).on('click', '.open_report_modal', function(event){
		event.preventDefault();
		$('.modal-backdrop').remove();
	});

	$(document).on('click', '.report_post', function(event){
	  event.preventDefault();
	  var post_id = $(this).attr('id');
	  var reported_user_id = $(this).attr('reported_user_id');
	  $.ajax({
	    url: '/report_user/',
	    type: "POST",
	    data: {
	      reported_user: {
	      	reported_user_id: reported_user_id,
	        post_id: post_id,
	        reason_for_report: $('#reason_' + post_id).val(),
	        description_of_offense: $('#description_' + post_id).val()
	      }
	    },
	    success: function(response) {
	      Swal.fire({
	        icon: 'success',
	        title: 'Success',
	        text: 'Report submitted successfully!',
	        timer: 3000,
            timerProgressBar: true,
	      });
	    },
	    error: function(xhr, status, error) {
	      Swal.fire({
	        icon: 'error',
	        title: 'Error',
	        text: 'Report submission failed. Please try again later.',
	      });
	    },
	  });
	});
});
