$(document).on('turbolinks:load', function() {
  $('body').on("click", ".select-author", function(){
    $.ajax({
      url: '/i_am_an_author/' + $(this).attr('data-id'),
      type: "POST",
      dataType: 'script',
      data: {},
    });
  });

  $(document).off("change", "#sort_pubmed_paper").on("change", "#sort_pubmed_paper", e => {
    var sort = $("#sort_pubmed_paper").val();
    const $this = $(e.currentTarget)
    $.ajax({
      url: '/users/'+ $this.data('id') + '/pubmed_papers/',
      type: "GET",
      data: {sort: sort}
    });
  });

  $(".groups_search_input").keyup(function(){
    $.ajax({
      url:"/library/",
      type: 'GET',
      dataType: 'script',
      data:{search: $(this).val()}
    });
  });

  $('body').on("change", ".article_type_filter, .source_filter", function(){
    if (!this.checked){
      this.checked = false;
      this.form.submit();
    } else {
      const checkboxes = document.querySelectorAll('.article_type_filter');
      checkboxes.forEach((checkbox) => {
        checkbox.checked = false;
      });
      this.checked = true;
      this.form.submit();
    }
  });

  $('body').on("change", ".date_filters", function(){
    clearCustomFields();
    if (!this.checked){
      this.checked = false;
      this.form.submit();
    } else {
      document.querySelector('.custom_date_filter').checked = false;
      $('#custom-date-fields').addClass('d-none');
      const checkboxes = document.querySelectorAll('.date_filters');
      checkboxes.forEach((checkbox) => {
        checkbox.checked = false;
      });
      this.checked = true;
      this.form.submit();
    }
  });

  $('body').on("click", ".custom_date_filter", function(){
    toggleCustomDateFields();
    if (!this.checked){
      clearCustomFields();
      this.form.submit();
    } else {
      this.checked = true;
    }
  });

  if ($('.publications_carousel_pagination')[0]){
    var totalItems = $('.carousel-item').length*2;
    var currentIndex = 0;
    updatePagination(currentIndex, totalItems);

    $('.carousel-control-prev').click(function() {
      currentIndex = (currentIndex - 2 + totalItems) % totalItems;
      updatePagination(currentIndex, totalItems);
    });

    $('.carousel-control-next').click(function() {
      currentIndex = (currentIndex + 2) % totalItems;
      updatePagination(currentIndex, totalItems);
    });
  }

});

function updatePagination(currentIndex, totalItems) {
  var paginationCount = (currentIndex + 1) + '-' + (currentIndex + 2);
  $('#publications_carousel_pagination_count').text(paginationCount);
}

function clearCustomFields() {
  document.getElementById('custom-date-fields').querySelectorAll('input[type="text"]').forEach(function(element) {
    element.value = '';
  });
}

function toggleCustomDateFields() {
  const checkboxes = document.querySelectorAll('.date_filters');
  checkboxes.forEach((checkbox) => {
    checkbox.checked = false;
  });

  const customDateFields = document.getElementById("custom-date-fields");
  if (customDateFields.style.display === "none") {
    customDateFields.style.display = "block";
  } else {
    customDateFields.style.display = "none";
  }
}