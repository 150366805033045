import $ from "jquery";

$(document).on('turbolinks:load', function(){
	$(document).on('change', '#research_center_therapeutic_focus', function(){
		$.ajax({
		  url: '/research_centers',
		  type: "get",
		  data: {therapeutic_focu: $(this).val()}
		});
	});
	$('body').on('keyup', '.research_center_search', function(e) {
		let checked_items
		try {
			checked_items = JSON.parse( $('#checked_items_'+e.target.id).val());
		}
		catch (err){
			null
		}
		$.ajax({
			url: '/research_centers/search',
			type: 'GET',
			data: {param: e.target.id, search: this.value, checked_items: checked_items}
		});
	});

	$('body').on('change', '#research_therapeutic_focu', (e)=>{
		const checkedCheckboxes = document.querySelectorAll('.therapeutic_focus_search_fields:checked');
		const checkedCheckboxIds = [];
		// loop through the checked checkboxes and push their IDs to the array
		checkedCheckboxes.forEach(checkedCheckbox => {
			checkedCheckboxIds.push(checkedCheckbox.value);
		});
		$.ajax({
			url: '/research_centers',
			type: 'GET',
			data: {param: e.target.id, therapeutic_focu: checkedCheckboxIds}
		});
	});

	$('#send-invite-research-center').click(function(){
	  var email = $('#input-email').val();
	  const id = parseInt($('#input-email-group').attr('value'));
	  const elements = document.getElementsByClassName('job_management_board_filter_role_dropdown_text');
	  const role = elements[0].textContent.trim().toLowerCase();

	  const pattern = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
	  if(email.length  == 0){
	    alert("please enter email address ");
	  }
	  else{
	    if (email.match(pattern)) {
	      $.ajax({
	        url: '/research_centers/'+ id +'/invite_user',
	        data: { email,role },
	        method: 'GET',
	        success: function (data) {
	          console.log(data)
	        },
	        error: function (data) {
	          console.log(data)
	        }
	      })
	    }
	    else{
	      alert("please input valid email address like 'abc@gmail.com'");
	    }
	  }
	})
});
