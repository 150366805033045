import consumer from "./consumer"
$(document).on('turbolinks:load', function() {
  // Add ActionCable subscription to listen for progress updates
  var progressChannel = consumer.subscriptions.create({
    channel: 'GroupsImportChannel',
  }, {
    received: function(data) {
      let { first_name, last_name, progress } = data
      if (jquery('#importProgressModal').length) {
        progress = parseFloat(progress).toFixed(2)
        jquery('#importProgressModal .progress-bar').css('width', `${progress}%`)
        jquery('#importProgressModal .progress-bar').text(`${progress}%`)
        jquery('#importProgressModal .progress-bar').attr('aria-valuenow', progress)
        jquery('#importProgressModal ul').append(`<li>Importing ${first_name} ${last_name}</li>`)
        jquery('#importProgressModal').modal('show')
      }
    },
  });
});