import Sortable from 'sortablejs';
$(document).on('turbolinks:load', function() {

  $(document).on('click', '.company_ranking_item_delete', function(e) {
    e.stopPropagation();
    e.preventDefault();

    $('.company_ranking_item_delete_modal').removeClass('show');
    $(this).addClass('show');
    $(this).next('.company_ranking_item_delete_modal').addClass('show');
  });

  $(document).on('click', '.company_ranking_item_delete_modal_close, .company_ranking_item_delete_modal_cancel_btn', function(e) {
    e.stopPropagation();
    e.preventDefault();

    $(this).addClass('.company_ranking_item_delete.modal_delete');
    $(this).parent().parent().removeClass('show');
  });

  $(document).on('click', '.remove_company_rank_modal_apply_btn', function(e) {
    var selectedOption = $(this).closest('.company_ranking_item').find('input[name^="candidate_gender"]:checked').val();
    var rank = $(this).data('rank');
    var company_id = $(this).data('company');
    $.ajax({
      url: '/remove_company_ranking',
      data: { rank: rank, option: selectedOption, company_id: company_id },
      type: 'DELETE',
      dataType: 'script'
    });
  });

  $(document).on('click', '.remove_student_rank_modal_apply_btn', function(e) {
    var selectedOption = $(this).closest('.company_ranking_item').find('input[name^="candidate_gender"]:checked').val();
    var rank = $(this).data('rank');
    var company_id = $(this).data('company');
    $.ajax({
      url: '/remove_student_ranking',
      data: { rank: rank, option: selectedOption, company_id: company_id },
      type: 'DELETE',
      dataType: 'script'
    });
  });

  $(document).on('click', function(e) {
    if (!$(e.target).closest('.company_ranking_item_delete_modal').length && !$(e.target).hasClass('company_ranking_item_delete')) {
      $('.company_ranking_item_delete_modal').removeClass('show');
    }
  });

	$('.see-more-or-less').on('click', function(e) {
    e.preventDefault();
    var $profileText = $(this).prev('.user_research_summary');
    $profileText.toggleClass('truncate-after-six-line');
    
    if ($profileText.hasClass('truncate-after-six-line')) {
      $(this).html('Read More');
      $(this).removeClass('open');
    } else {
      $(this).html('See Less');
      $(this).addClass('open');
    }
  });

  $("#search_communities").keyup(function(){
    $.ajax({
      url:"/users/" + $(this).attr('data-id'),
      type: 'GET',
      data:{search: $(this).val()}
    });
	});

  $('#partner_id').on('changed.bs.select', function (e, clickedIndex, previousValue) {
      $.ajax({
        url:"/admin/company_partners/display_sponsor_level",
        type: 'GET',
        data:{company_partner_id: $(this).val(), summit_id: $(e.target).data()['summit'] }
      });
  });

  $('#company_partner_id').on('changed.bs.select', function (e, clickedIndex, previousValue) {
      $.ajax({
        url:"/admin/company_partners/display_sponsor_level",
        type: 'GET',
        data:{company_partner_id: $(this).val(), workshop_id: $(e.target).data()['workshop'] }
      });
  });
  
  $('.show-more-community').slice(0,5).show();
  $('#community-see-all').on('click', function (e){
    e.preventDefault();
    var elemText = $(this).text();
    if (elemText === 'Show More Communities'){
      let divCount = $('.show-more-community:hidden').length;
      $('.show-more-community:hidden').slice(0,divCount).slideDown();
      $(".down-arrow").css({'transform': 'rotate(-180deg)'});
      $('#community-see-all').text('Show Fewer Communities');
    }
    if (elemText === 'Show Fewer Communities'){
      let divCount = $('.show-more-community').length;
      $('.show-more-community').slice(5,divCount).slideUp();
      $(".down-arrow").css({'transform': 'none'});
      $('#community-see-all').text('Show More Communities');
    }
  });

  $('#presentation_container').on('cocoon:after-insert', function(e, added_thing) {
    try {
      ClassicEditor
          .create(  document.querySelector( '#'+ added_thing.find('textarea')[0].id ) )
          .then( editor => {
            window["school_description_editor"] = editor;
          } )
          .catch( error => {
            console.error( 'There was a problem initializing the editor.', error );
          } );
    } catch (e) {
      window["school_description_editor"] = $(document.querySelector( '#'+ added_thing.find('textarea')[0].id ))
      $.fn.getData = function () {
        return $(this).val()
      }
    }

  });
  $(() => {
    $('.presentation_ck_editor').each((index, item) => {
      let id = item.id
      try {
        ClassicEditor
            .create(  document.querySelector( '#'+ id ) )
            .then( editor => {
              window["update_presentation_description_editor_"+ id] = editor;
            } )
            .catch( error => {
              console.error( 'There was a problem initializing the editor.', error );
            } );
      } catch (e) {
        window["update_presentation_description_editor_"+ id] = $(document.querySelector( '#'+ id ))
        $.fn.getData = function () {
          return $(this).val()
        }
      }
    })
  })
  $('#user_type').on('change', function () {

    let currentElement  = $(this).val();

    if( currentElement === 'academic_researcher_faculty'){
      $('#research-center').removeClass('d-none');
      $('#research-center').addClass('d-block');

      $('#research-center-tab').removeClass('d-none');
      $('#research-center-tab').addClass('d-block');
    } else if (currentElement === 'undergraduate_student') {
      $('#training-and-fellowships').removeClass('d-block').addClass('d-none');
      $('#training-and-fellowships-tab').removeClass('d-inline-block').addClass('d-none');
    } else {
      $('#research-center').removeClass('d-block');
      $('#research-center').addClass('d-none');

      $('#research-center-tab').removeClass('d-block');
      $('#research-center-tab').addClass('d-none');

      $('#training-and-fellowships').addClass('d-block').removeClass('d-none');
      $('#training-and-fellowships-tab').addClass('d-inline-block').removeClass('d-none');
    }
  });

  jquery('#user_form').validate({
    rules: {
      'user[first_name]': {
        required: true,
        notblank: true,
        validName: true,
        minlength: 1,
        maxlength: 50,
      },
      'user[last_name]': {
        required: true,
        notblank: true,
        validName: true,
        minlength: 1,
        maxlength: 50
      },
      'user[password]': {
        required: true,
        notblank: true,
        minlength: 6,
        maxlength: 50
      },
      'user[password_confirmation]': {
        required: true,
        notblank: true,
        minlength: 6,
        maxlength: 50,
        equalTo: "#user_password"
      },
      'user[email]': {
        required: true,
        isEmail: true,
        email: true,
        remote: {
          url: '/email_exist',
          sendParam: 'email',
          method: 'POST',
        }
      }
    },
    messages: {
      'user[email]': {
        remote: 'Email already exist.'
      }
    },
    errorPlacement: function(error, element) {
      if (element.attr("name") == "user[first_name]" || element.attr("name") == "user[last_name]" ) {
        error.insertAfter(element);
      } else {
        error.insertAfter(element.parent());
      }
    }
  });

  jquery.validator.addMethod("notblank", function(value) {
    return (value !== '' && value.trim() !== '');
  }, "This field can't be blank.");

  jquery.validator.addMethod("validName", function(value) {
    var regex = /^[A-Za-z'\-\s]+$/;
    return regex.test(value);
  }, "Name should consist of letters, apostrophe, or hyphens only.");

  jquery.validator.addMethod("isEmail", function(value) {
    var regex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    return regex.test(value);
  }, "Email is not valid.");

  $('body').on('change', '.user_search_fields', ()=>{
    $( "#target" ).submit();
  });

  $('body').on('click', '.student-profile__connections-link', (e)=>{
    $.ajax({
      url: '/saved_lists',
      type: 'GET',
      dataType: 'script',
      data: {user_id: $(e.target).data('id') || $(e.target).parent().data('id')}
    })
  })
  $('body').on('click', '.create_new_saved_folder', ()=>{
    $('.saved_item_form_list').hide()
    $('.new_saved_item_form').show()
  });

  document.onkeydown=function(){
      if($( "#target" ).length > 0 && window.event.keyCode=='13'){
        $( "#target" ).submit();
      }
  }

  $('body').on('keyup', '.search_for_select_box', function(e) {
    let checked_items
    try {
      checked_items = JSON.parse( $('#checked_items_'+e.target.id).val());
    }
    catch (err){
      null
    }
     $.ajax({
       url: '/users/search',
       type: 'GET',
       data: {param: e.target.id, search: this.value, checked_items: checked_items}
    });
  });

  $('.text_area_ck').each((index, item) => {
    try {
      ClassicEditor
          .create( item )
          .then( editor => {
            window.editor = editor;
          } )
          .catch( error => {
            console.error( 'There was a problem initializing the editor.', error );
          } );
    } catch (e) {
      window.editor = $(item)
      $.fn.getData = function () {
        return $(this).val()
      }
    }
  })

  $(document).on('click', '#rank_user', function(e){
    var selectedValue = this.dataset.userId;
    $.ajax({
      url: '/users/'+ selectedValue +'/company_ranking',
      data: { rank: this.dataset.rank, company_id: this.dataset.company },
      type: 'POST',
      dataType: 'script',
    });
  });

  if ( document.getElementById('user-all-rankings') ) {
    var el       = document.getElementById("user-all-rankings");
    var user_id  = el.getAttribute('data-user-id');;
    var sortable = Sortable.create(el, {
      delay: 200,
      touchStartThreshold: 5,
      delayOnTouchOnly: false,
      onEnd: function(event) {
        var rankings_order = [];
        $(".company_ranking_item").each(function(e) {
          // rankings_order.push($(this).attr('id').match(/(\d+)/g)[0])
          var logoElement = $(this).find(".company_ranking_item_logo");
          var rankingId = $(this).attr("id");
          if (logoElement.length > 0) {
           rankings_order.push(logoElement.attr("id"));
          }else{
            rankings_order.push(0);
          }
        })
        $.ajax({
          type: "PATCH",
          dataType: 'script',
          url: "/users/update_rankings_position",
          data: {
            new_ranking_ids_order: rankings_order
          }
        });
      }
    });
  }
});

