$(document).on('turbolinks:load', function() {

  $('[data-toggle="tooltip"]').tooltip();

  var debounceTimeout;
	var user_ids = [];
	// var group_users_count = parseInt($('.group_users_count').val());
	var group_users_count = Math.floor(Math.random() * (10000 - 1000 + 1)) + 1000;
	$(document).on('keyup', '#search-group-users', function(event){
		if (event.key === "Enter") {
			return;
		}
		$.ajax({
			url: $(".users_path").val(),
			type: "GET",
			dataType: 'script',
			data: {search: $(this).val(), user_ids: user_ids, current_object_id: $('.group_id').val(), object_type: "Group"},
		});
	});

	$(document).on('click', '.search-group-user', function(){
		if (!isNaN(group_users_count)) {
			$('#group-selected-users').append("<span class='search-group-user-block'><input type='hidden' name='group[group_users_attributes]["+ group_users_count + "][user_id]' value="+ $(this).attr('id') + "><p>" + $(this).text() + "</p><button class='remove-search-select-user'>x</button></span>");
			group_users_count++;
			user_ids.push($(this).attr('id'));
			$('#search-group-users').val("");
			$(this).parent().parent().remove();
		}
	});

	$(document).on('click', '.remove-search-select-user', function(){
		var removeItem = $(this).prev().prev().val();
		user_ids = jQuery.grep(user_ids, function(value) {
			return value !== removeItem;
		});
		$(this).parent().remove();
	});

	$('#search-users-to-invite').keyup( (e) =>{
		const id = parseInt($('#users-for-invite').attr('value'));
		let name = e.target.value
    if (name.length == 0 || name.length >= 3){
    	$.ajax({
	      url: '/groups/'+ id +'/filter_users',
	      data: { name },
	      method: 'GET',
	      success: function (data) {
	        console.log(data)
	      },
	      error: function (data) {
	        console.log(data)
	      }
	    })
    }
	})

	 $('#send-invite-group').click(function(){
    var email = $('#input-email-group').val();
    const id = window.location.pathname.split('/')[2];
    let role = document.getElementById("user_role").value.toLowerCase();
    if(email.length  == 0){
      alert("please enter email address ");
    }
    else if (role.trim() === "") { 
      alert("Please select role.");
    }
    else{
      if(email.includes('@') && email.includes('.com')){
        $.ajax({
          url: '/groups/'+ id +'/invite_users_by_email',
          data: { email,role },
          method: 'GET',
          success: function (data) {
            console.log(data)
          },
          error: function (data) {
            console.log(data)
          }
        })
      }
      else{
        alert("please input valid email address like 'abc@gmail.com'");
      }
    }
  })

	$('#send-invite-nih_institute_and_center').click(function(){
    var email = $('#input-email').val();
    const id = parseInt($('#input-email-group').attr('value'));
    const elements = document.getElementsByClassName('job_management_board_filter_role_dropdown_text');
    const role = elements[0].textContent.trim().toLowerCase();
    const pattern = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    if(email.length  == 0){
      alert("please enter email address ");
    }
    else{
      if (email.match(pattern)) {
        $.ajax({
          url: '/nih/'+ id +'/invite_user',
          data: { email,role },
          method: 'GET',
          success: function (data) {
            console.log(data)
          },
          error: function (data) {
            console.log(data)
          }
        })
      }
      else{
        alert("please input valid email address like 'abc@gmail.com'");
      }
    }
  })

  $('#send-invite-company').click(function () {
    var email = $('#input-email').val().trim();
    const id = parseInt($('#input-email-group').attr('value'));
    const elements = document.getElementsByClassName('job_management_board_filter_role_dropdown_text');
    const role = elements[0].textContent.trim().toLowerCase();
    const pattern = /^((?!.*@(test|mailinator)\b)([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

    if (email.length == 0) {
      alert("Please enter an email address.");
    } else if (email.length > 50) {
      alert("Email address cannot exceed 50 characters.");
    } else {
      if (email.match(pattern)) {
        $.ajax({
          url: '/companies/' + id + '/invite_user',
          data: { email, role },
          method: 'GET',
          success: function (data) {
            console.log(data)
          },
          error: function (data) {
            console.log(data)
          }
        });
      } else {
        alert("Please input a valid email address like 'abc@gmail.com'");
      }
    }
  });


  $('body').on('keyup', '#search_group_member', (e)=> {
    clearTimeout(debounceTimeout);
    var delay = 500;
    debounceTimeout =setTimeout(function() {
      var th = $(e.target);
      $.ajax({
        type: "GET",
        url: th.data('url'),
        dataType: 'script',
        data: {
          'search' : th.val()
        }
      });
      }, delay);
  });

});
