// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start();
require("turbolinks").start();
require('jquery');
require('jquery-validation');
import * as ActiveStorage from "@rails/activestorage";
import "channels";
import "../stylesheets/application";
import * as bootstrap from 'bootstrap';
import "jquery-validation/dist/jquery.validate";
import "@fortawesome/fontawesome-free/css/all";
import 'select2';
import 'select2/dist/css/select2.css';

ActiveStorage.start();


window.Swal = require('sweetalert2');
require('select2');
require('bootstrap');
require('bootstrap-select/js/bootstrap-select');
$.fn.selectpicker.Constructor.BootstrapVersion = '4.6.0';
require('fontawesome-4.7');
require('bootstrap-autocomplete')
require('@nathanvda/cocoon');
require('@nathanvda/cocoon');
import 'jquery-textcomplete';
import "emojionearea"
// require('@ckeditor/ckeditor5-build-classic')
var moment = require('moment')
require('cropper')
import "daterangepicker"
import Sortable from "sortablejs";
window.Sortable = require("sortablejs")

require('./gijgo');
require('./groups');
require('./communities');
require('./posts');
require('./candidate');
require('./settings');
require('./companies');
// require('./rails-ujs');
require('./research_centers');
require('./schools');
require('./pubmed_papers');
require('./script');
require('./registrations/form');
require('./registrations/company_form');
require('./sessions/form');
require('./passwords/form');
require('./user_tracking');
require('./conversations');
require('./job');
require('./saved_lists');
require('./summit');
require('./event');
require('./webinar');
require('./user');
require('./custom');
require('./follows');
require('./report_users');
require('./manager_users');
require('./user_quries');
require('./favorites');
require('./search');
require('./news');
require('./organizations');
require('./privacy_setting');
require('./admin/summits');
require('./select2setup');
require('./connections');

global.moment = moment;
window.moment = moment;
window.Modal = bootstrap.Modal;

import "controllers"
