$(document).on('turbolinks:load', function() {
	$('body').on('click', '.follow_obj', function(event){
		event.preventDefault();
		$.ajax({
			url: '/follow/'+ $(this).attr('data-type') + '/' + $(this).attr('id'),
			type: "POST",
			dataType: 'script',
			data: {style: $(this).attr('data-style')}
		});
	});

	$('body').on('click', '.unfollow_obj', function(event){
		event.preventDefault();
		$.ajax({
			url: '/unfollow/' + $(this).attr('data-type') + '/' + $(this).attr('id'),
			type: "POST",
			dataType: 'script',
			data: {style: $(this).attr('data-style')}
		});
	});
});
