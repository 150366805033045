import Sortable from 'sortablejs';
import $ from 'jquery';
import 'select2/dist/css/select2.css';
import 'select2';

$(document).on('turbolinks:load', function() {
	if ($('#therapeutic-focus').length > 0) {
		$('#therapeutic-focus').select2({
			placeholder: "Choose a Therapeutic Focus"
		});
	}

	$(document).on('click', '#add_new_company_user', function(){
		// event.preventDefault();
		var type;
		if($(this).data('type') === 'Research'){
			type = "research_centers";
		}
		else if($(this).data('type') === "Company"){
			type = "companies";
		}
		else{
			type = "schools";
		}

		var email = $('#new_company_user_email').val();
		$.ajax({
			url: '/admin/'+ type +'/' + $(this).data('id') + '/invite_user',
			type: "POST",
			data: {
				invitation_email: email,
				authenticity_token: $('meta[name=csrf-token]').attr('content')
			},
		});
	});

	$(document).on('keyup', '#search_to_invite_company_user', function(e){
		if (e.key === "Enter") {
			return;
		}
		var search = $(this).val();
		$.ajax({
			url: '/admin/search_to_invite_user',
			type: "GET",
			dataType: 'script',
			data: {
				search,
				id: $(this).data('id'),
				type: $(this).data('type'),
				authenticity_token: $('meta[name=csrf-token]').attr('content')
			},
		});
	});

	$(document).on('click', '.invite_company_user', function(e){
		e.preventDefault();
		var user_id = $(this).attr('id');
		var $this = $(this)
		$.ajax({
			url: '/admin/manager_users/'+ $(this).data('objId') +'/invite_user',
			type: "POST",
			data: {
				user_id,
				type: $(this).data('type'),
				authenticity_token: $('meta[name=csrf-token]').attr('content')
			},
			error: res => {
				if (res.responseJSON.message) {
					Swal.fire({
						title: 'Error!',
						text: res.responseJSON.message,
						icon: 'error'
					})
					$this.removeClass('d-none');
					$this.next().addClass('d-none');
				}
			}
		});
		$(this).addClass('d-none');
		$(this).next().removeClass('d-none');
	});
	$('body').on('change', '#therapeutic_focu', (e)=>{
		const checkedCheckboxes = document.querySelectorAll('.therapeutic_focus_search_fields:checked');
		const checkedCheckboxIds = [];
		// loop through the checked checkboxes and push their IDs to the array
		checkedCheckboxes.forEach(checkedCheckbox => {
			checkedCheckboxIds.push(checkedCheckbox.value);
		});
		$.ajax({
			url: '/companies',
			type: 'GET',
			data: {param: e.target.id, therapeutic_focu: checkedCheckboxIds}
		});
	});

	$(document).on('keyup', '#company_followers', function(){
		var search = $(this).val();
		var id = $(this).data('id');
		$.ajax({
			url: '/companies/'+ id +'/company_followers',
			type: "GET",
			dataType: 'script',
			data: {search: search},
		});
	});

	$('.save_company').on('click', (e)=>{
		var fd = new FormData($('#obj_setting_form')[0]);
		if($('#obj_image')[0].files[0]){
			fd.append($(e.target).data('singular-type')+"[logo]", $('#obj_image')[0].files[0]);
		}

		if($('#obj_banner')[0].files[0]){
			fd.append($(e.target).data('singular-type')+"[banner]", $('#obj_banner')[0].files[0]);
		}
		if($('#fileInput')[0].files[0]){
			fd.append($(e.target).data('singular-type')+"[attachments_attributes][0][video]", $('#fileInput')[0].files[0]);
    }

    document.querySelector('textarea[name="name"]').removeAttribute('required');

    $.ajax({
      url: $(e.target).data('url'),
      type: $('#obj_setting_form').attr('method'),
      dataType: 'json',
      headers: {
         authenticity_token: $('[name="csrf-token"]')[0].content
       },
      data: fd,
      processData: false,
			contentType: false,
      success:function() {
      }
    });
	});

	$('body').on('keyup', '.therapeutic_focus_search_fields', function(e) {
		let checked_items
		try {
			checked_items = JSON.parse( $('#checked_items_'+e.target.id).val());
		}
		catch (err){
			null
		}
		$.ajax({
			url: '/companies/search',
			type: 'GET',
			data: {param: e.target.id, search: this.value, checked_items: checked_items}
		});
	});


	$('body').on('change', '#obj_skill', ()=>{
	  load_skills();
	});

	$('body').on('click', '.company_skill_remove', (e)=>{
	  remove_skill(e);
	  load_skills();
	});

	if ( document.getElementById('company-all-rankings') ) {
	  var el         = document.getElementById("company-all-rankings");
	  var company_id = el.getAttribute('data-company-id');
	  var sortable   = Sortable.create(el, {
	    delay: 200,
	    touchStartThreshold: 5,
	    delayOnTouchOnly: false,
	    onEnd: function(event) {
	      var rankings_order = [];
	      $(".company_ranking_item").each(function(e) {
	       var logoElement = $(this).find(".company_ranking_item_logo");
	        // rankings_order.push($(this).attr('id').match(/(\d+)/g)[0])
	        var rankingId = $(this).attr("id");
	        if (logoElement.length > 0) {
	         rankings_order.push(logoElement.attr("id"));
          }else{
            rankings_order.push(0);
          }
	      })
	      $.ajax({
          type: "PATCH",
	        dataType: 'script',
	        url: "/companies/"+ company_id +"/update_rankings_position",
	        data: {
	          new_ranking_ids_order: rankings_order
	        }
	      });
	    }
	  });
	}
	
	function load_skills(){
	  $('.selected_skill').html("");
	  var companySkill = $('#obj_skill').val();
	  var selectedVal = $("#obj_skill :selected");
	  if(companySkill && companySkill.length > 0){
		  for(var i=0; i < companySkill.length; i++ ){
		    if(companySkill[i]){
		        append_selected_value(companySkill[i], selectedVal[i].text);
		    }
		  }
		}
	}

	load_skills();
	function append_selected_value(optionVal, optionName){
		var selected_job_skill = "<span class='d-flex create_job_skills_tag'><span class='d-flex create_job_skills_tag_text' data-option = '"+ optionName +"' data-val = '"+ optionVal +"'>"+optionName+"</span><span class='d-flex create_job_skills_tag_remove_link company_skill_remove'></span></span>";
	  $('.selected_skill').append(selected_job_skill);
	}

	function remove_skill(e){
		var th = $(e.target);
		var text = th.siblings().data('option');
		var regex = new RegExp(text + '\, |\, ' + text, 'g');
		var regex_last = new RegExp(text, 'g');
		$(".company_skill_tag button .filter-option-inner-inner").text($(".company_skill_tag button .filter-option-inner-inner").text().replace(regex , "").replace(regex_last, 'Select Benefits'));
		$('#obj_skill option[value="'+ th.siblings().data('val')+'"]').prop('selected', false);
		$('#job_benifits').trigger('change');
	}
	const dropZoon = document.querySelector('#dropZoon');
	const fileInput = document.querySelector('#fileInput');
	const fileDetails = document.querySelector('#fileDetails');
	const uploadedFile = document.querySelector('#uploadedFile');
	const uploadedFileInfo = document.querySelector('#uploadedFileInfo');
	const uploadedFileName = document.querySelector('.uploaded-file__name');
	const uploadedFileCounter = document.querySelector('.uploaded-file__counter');
	const fileSizeMb = document.querySelector('.create_job_media_item_size');
	if(dropZoon){
		dropZoon.addEventListener('dragover', function (event) {
		  event.preventDefault();

		  dropZoon.classList.add('drop-zoon--over');
		});

		dropZoon.addEventListener('dragleave', function () {
		  dropZoon.classList.remove('drop-zoon--over');
		});

		dropZoon.addEventListener('drop', function (event) {
		  event.preventDefault();
		  dropZoon.classList.remove('drop-zoon--over');
		  const file = event.dataTransfer.files[0];
		  uploadFile(file);
		});

		fileInput.addEventListener('change', function (event) {
		  const file = event.target.files[0];
		  uploadFile(file);
		});
	}


	function uploadFile(file) {
	  const fileReader = new FileReader(); 
	  const fileSize = (file.size / (1024*1024)).toFixed(2) + 'MB';
	  if (true) {
	    dropZoon.classList.add('drop-zoon--Uploaded');
	    uploadedFile.classList.remove('uploaded-file--open');
	    uploadedFileInfo.classList.remove('uploaded-file__info--active');
	    fileReader.addEventListener('load', function () {
	      setTimeout(function () {
	        fileDetails.classList.add('file-details--open');
	        uploadedFile.classList.add('uploaded-file--open');
	        uploadedFileInfo.classList.add('uploaded-file__info--active');
	      }, 500);

	      uploadedFileName.innerHTML = file.name;
	      fileSizeMb.innerHTML = fileSize;

	      progressMove();
	    });

	    fileReader.readAsDataURL(file);
	  } else {
	    this;

	  }
	}

	$('body').on('click', '.remove_file', ()=>{
	  fileInput.value = '';
	  $('.myprogressBar').attr('style','width: 0%');
	  uploadedFileName.innerHTML = 'File Name';
	  fileSizeMb.innerHTML = '0 MB';
	  uploadedFileCounter.innerHTML = '0%';
	});

	function progressMove() {
	  let counter = 0;
	  setTimeout(() => {
	    let counterIncrease = setInterval(() => {
	      if (counter === 100) {
	        clearInterval(counterIncrease);
	      } else {
	        counter = counter + 10;
	        $('.myprogressBar').attr('style','width:'+ counter +'%');
	        uploadedFileCounter.innerHTML = `${counter}%`;
	      }
	    }, 100);
	  }, 600);
	}

});
