$(document).on('turbolinks:load', function() {

  $('body').on('change', '#list-filter-select', filterLists);

  $('body').on('keyup', '#search_list_input', function(){
    setTimeout(function() {
      filterLists();
    }, 100);
  });
});

function filterLists() {
  var selectedOption = $('#list-filter-select').val();
  var search = $('#search_list_input').val();
  $.ajax({
    url: '/lists',
    method: 'GET',
    dataType: 'script',
    data: { sort: selectedOption, search: search }
  });
}