$(document).on('turbolinks:load', function() {
	var participant_ids = [];
	var participant_id = [];

	$(document).on('click', '.conversation_list_item', function(e){
		e.preventDefault();
		var conversation_id = $(this).data('conversation-id');
		$.ajax({
			url: "/conversations/" + conversation_id + "/load_conversation",
			type: "GET",
			dataType: 'script',
		});
	});

	// $('.more_icon').click(function(e){
	// 	e.stopPropagation();
  //   e.preventDefault();
	// 	$(this).parent().parent().parent().find('.messaging-dialog__nav').toggleClass('d-none');
	// });

	$(document).on('keyup', '#search_group_participant_name', function(event){
		if (event.key === "Enter") {
			return;
		}
		$.ajax({
			url: '/users/search_participant_for_group',
			type: "GET",
			dataType: 'script',
			data: {search: $(this).val(), participant_ids: participant_ids},
		});
	});

	$(document).on('click', '.search-group-participant', function(){
		$('#group-selected-participants').append("<span class='search-group-participant-block'><input type='hidden' name='user_ids[]' value="+ $(this).attr('id') + "><p>" + $(this).text() + "</p><button class='remove-search-select-participant'>x</button></span>");
		participant_ids.push($(this).attr('id'));
		$('#search_group_participant_name').val("");
		$(this).parent().remove();
	});

	$(document).on('click', '.remove-search-select-participant', function(){
		var removeItem = $(this).prev().prev().val();
		participant_ids = jQuery.grep(participant_ids, function(value) {
			return value !== removeItem;
		});
		$(this).parent().remove();
	});

	$(document).on('click', '#group_chat_next', function(e){
		e.preventDefault();
		var group_name = $('#group_name').val();
		if (group_name === "" || group_name.replace(/\s+/g, '').length === 0 || participant_ids.length === 0) {
			if (group_name === "" || group_name.replace(/\s+/g, '').length === 0) {
				alert("Group name can not be empty");
			}
			if (participant_ids.length === 0) {
				alert("Add atleast 1 participant");
			}
			return;
		}
		$('.group_participants_select').addClass('d-none');
		$('.group_participants_message').removeClass('d-none');
	});

	$(document).on('click', '#create_group_chat', function(e){
		e.preventDefault();
		var body = $('#group_message').val();
		if (body === "" || body.replace(/\s+/g, '').length === 0) {
			alert("Message can not be empty");
			return;
		}
		$.ajax({
			url: '/conversations/new_group_create',
			type: "POST",
			data: {conversation: {name: $('#group_name').val(), participant_ids: participant_ids}, message: {body: body}},
		}).done(function(){
			window.location.href = "/conversations";
		});
	});

	$(document).on('keyup', '#search_direct_participant_name', function(event){
		if (event.key === "Enter") {
			return;
		}
		$.ajax({
			url: '/users/search_participant_for_direct',
			type: "GET",
			dataType: 'script',
			data: {search: $(this).val()},
		});
	});

	$(document).on('click', '.select-direct-participant', function(e){
		e.preventDefault();
		participant_id.push($(this).attr('id'));
		$('.direct_participant_select').addClass('d-none');
		$('.direct_participant_message').removeClass('d-none');
	});

	$(document).on('click', '#create_direct_chat', function(e){
		e.preventDefault();
		var body = $('#direct_message').val();
		var user_id = $(this).data("user-id");
		if (body === "" || body.replace(/\s+/g, '').length === 0) {
			alert("Message can not be empty");
			return;
		}
		$.ajax({
			url: '/messages',
			type: "POST",
			data: {participant_id: participant_id[0], message: {body: body, user_id: user_id}},
		}).done(function(){
			window.location.href = "/conversations";
		});
	});

	$('body').on('keyup', '.search_conversation', function(e){
		e.preventDefault();
		if (event.key === "Enter") {
			return;
		}
		$.ajax({
			url: '/conversations/search_conversation',
			type: "GET",
			dataType: 'script',
			data: {search: $(this).val(), popup: $(this).data('popup')},
		});
	});

	$('body').on('click', ".toggle_conversation_popup", function(){
		var conversation_popup = $('.conversation_popup');
		var cross_conversation =  $('.cross_conversation_popup');
		if(conversation_popup.hasClass('small')){
			conversation_popup.removeClass('small');
			cross_conversation.addClass('d-none');
			conversation_popup.addClass('minimized');
		}
		else {
			conversation_popup.removeClass('minimized');
			conversation_popup.addClass('small');
			cross_conversation.removeClass('d-none');

			$.ajax({
				url: '/conversations/conversations_for_popup',
				type: "GET",
				dataType: 'script'
				// data: {search: $(this).val()},
			});
		}
	});

	$(document).on('click', ".cross_conversation_popup", function(){
		var conversation_popup = $('.conversation_popup');
		if(conversation_popup.hasClass('small')){
			conversation_popup.removeClass('small');
			conversation_popup.addClass('minimized');
		}
	});


	$(document).on('click', ".open_conversation_popup", function(){
		var conversation_id = $(this).attr('id');
		$.ajax({
			url: "/conversations/" + conversation_id + "/conversation_for_popup",
			type: "GET",
			dataType: 'script'
		});
	});

	$(document).on('click', ".toggle_conversation_popup_size", function(){
		var class_split = $(this).attr('id').split('_');
		var conversation_id = class_split[class_split.length - 2];
		var open_conversation_div = $('#conversation_' + conversation_id + '_popup');
		if(open_conversation_div.hasClass('messaging-dialog--small')){
			open_conversation_div.removeClass('messaging-dialog--small');
		}
		else {
			open_conversation_div.addClass('messaging-dialog--small');
		}
	});

	$(document).on('click', ".toggle_empty_conversation_popup_size", function(){
		var class_split = $(this).attr('id').split('_');
		var user_conversation_id = class_split[class_split.length - 2];
		var open_conversation_div = $('#user_conversation_' + user_conversation_id + '_popup');
		if(open_conversation_div.hasClass('messaging-dialog--small')){
			open_conversation_div.removeClass('messaging-dialog--small');
		}
		else {
			open_conversation_div.addClass('messaging-dialog--small');
		}
	});

	$('body').on('click', '.toggle_open_conversation_popup', function(){
		var conversation_popup = $('#conversation_' + $(this).attr('id') + '_popup');
		if(conversation_popup.hasClass('messaging-dialog--minimized')){
			conversation_popup.removeClass('messaging-dialog--minimized');
			$.ajax({
        url: "/read_ats/conversation/" + $(this).attr("id"),
        type: "POST"
      });
		}
		else {
			conversation_popup.addClass('messaging-dialog--minimized');
		}
	});

	$(document).on('click', '.toggle_open_empty_conversation_popup', function(){
		var user_conversation_popup = $('#user_conversation_' + $(this).attr('id') + '_popup');
		if(user_conversation_popup.hasClass('messaging-dialog--minimized')){
			user_conversation_popup.removeClass('messaging-dialog--minimized');
		}
		else {
			user_conversation_popup.addClass('messaging-dialog--minimized');
		}
	});

	$(document).on('click', '.close_conversation_popup', function(){
		$('#conversation_' + $(this).attr('id') + '_popup').remove();
	});

	$(document).on('click', '.close_empty_conversation_popup', function(){
		$('#user_conversation_' + $(this).attr('id') + '_popup').remove();
	});

	$(document).on('click', '.start_new_conversation_message', function(){
		$.ajax({
			url: "/conversations/start_new_conversation_message",
			type: "POST",
			dataType: 'script',
			data: {user_id: $(this).attr('id')}
		});
	});

	$(document).on('click', '.toggle_conversations_head', function(){
		var parent = $(this).parent().parent();
		if (parent.hasClass('closed')) {
			parent.removeClass('closed');
		}
		else {
			parent.addClass('closed');
		}
	});	

	$('body').on('click', '.edit_link', function(e){
		$(this).closest('.dropdown').toggleClass("show");
	});
// messages-center__new-message
});
