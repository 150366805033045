$(document).on('turbolinks:load', function() {
  $('body').on('keyup', '.search_for_select_box_news', function(e) {
      let checked_items
      try {
        checked_items = JSON.parse( $('#checked_items_'+e.target.id).val());
      }
      catch (err){
        null
      }
       $.ajax({
         url: '/news/search',
         type: 'GET',
         data: {param: e.target.id, search: this.value, checked_items: checked_items}
      });
  });
});
