import consumer from "./consumer";

$(document).on('turbolinks:load', function() {
  var conversation_body, messages_to_bottom;
  conversation_body = $('#conversation_body');

  if (conversation_body.length > 0) {
    messages_to_bottom = function() {
      return $('.messages-center__chat').scrollTop($('.messages-center__chat').prop("scrollHeight"));
    };
    var user_unsubscribe = function(){
      consumer.subscriptions.subscriptions.pop();
    };

    messages_to_bottom();
    // user_unsubscribe();
    var user_subscription = consumer.subscriptions.create({
      channel: "UsersChannel",
      user_id: conversation_body.data('user-id')
    }, {
      connected: function() {},
      disconnected: function() {},
      received: function(data) {
        if ($('.conversation_messages_body').find('#conversation_' + data['conversation_id'] + '_messages').length === 1) {
          $.ajax({
            url: "/load_message",
            type: "POST",
            dataType: 'script',
            data: {message_id: data['message_id'] },
          });
          $.ajax({
            url: "/read_ats",
            type: "POST",
            data: {message_id: data['message_id'] },
          });
        }
        if ($('#conversation_' + data['conversation_id'] + '_popup').length === 1) {
          $.ajax({
            url: "/load_message_for_popup",
            type: "POST",
            dataType: 'script',
            data: {message_id: data['message_id'] },
          });
          if (!$('#conversation_' + data['conversation_id'] + '_popup').hasClass("messaging-dialog--minimized")) {
            $.ajax({
              url: "/read_ats",
              type: "POST",
              data: {message_id: data['message_id'] },
            });
          }
        }
        if ($('#user_conversation_' + data['participant_id'] + '_popup').length === 1) {
          $.ajax({
            url: "/load_message_for_empty_popup",
            type: "POST",
            dataType: 'script',
            data: {message_id: data['message_id'], participant_id: data['participant_id'] },
          });
        }
      },
      send_message: function(personal_message, conversation_id, new_conversation, user_id, file, name, file_type, message_id) {
        var title = $(`#conversation_message_${conversation_id}`)
        title[0].innerText = personal_message
        return this.perform('send_message', {
          message: personal_message,
          conversation_id: conversation_id,
          new_conversation: new_conversation,
          user_id: user_id,
          file: file,
          name: name,
          file_type: file_type,
          message_id: message_id
        });
      }
    });

    $('body').on('submit', '#new_message', function(e) {
      var $this, textarea, conversation_id, new_conversation, user_id, file, reader, file_name, file_type, validImageTypes, file_extension, message_id, formData, id, progressBar;
      $this = $(this);
      conversation_id = $this.find('.submit').attr('id');
      textarea = $this.find('#message_body_' + conversation_id);
      new_conversation = $this.find('#new_conversation');
      user_id = $this.find('#user_id');
      message_id = $("#message_id").val();
      file = $this.find('#message_file_' + conversation_id);
      validImageTypes = ["jpg", "jpeg", "png", "pdf", "doc", "docx", "mp4", "MP4"];
      formData = new FormData();

      if ($.trim(textarea.val()).length > 0 || file[0].files.length > 0) {
        if (file[0].files.length > 0) {
          file_name = file[0].files[0].name;
          file_type = file[0].files[0].type;
          file_extension = file_name.substring(file_name.lastIndexOf('.')+1);
          if ($.inArray(file_extension, validImageTypes) < 0) {
            alert("invalid file");
            file.val('');
            return false;
          }
          const progressContainerbt = document.getElementById('progress-container');
            if (progressContainerbt) {
              return;
            }
          formData.append('file', file[0].files[0]);
          var progressBar = $("<progress/>", {
            "id": "progress-bar",
            "class": "progress-bar",
            "max": "100",
            "value": "0"
          });
          // $this.append(progressBar);

          var progressText = $("<span/>", {
            "id": "progress-text",
            "class": "progress-text",
            "text": "0%"
          });
          // $this.append(progressText);

          var progressContainer = $("<div/>", {
            "class": "progress-container video_upload_progress_container messages_video_upload_progress_container",
            "id": "progress-container"
          }).append(progressBar, progressText);

          $this.append(progressContainer);

          var xhr = new XMLHttpRequest();
          xhr.open('POST', '/uploads', true);
          xhr.upload.addEventListener("progress", function(e) {
            if (e.lengthComputable) {
              var percentComplete = Math.round((e.loaded / e.total) * 100);
              $("#progress-bar").attr("value", percentComplete);
              $("#progress-text").text(percentComplete + "%");
            }
          }, false);
          xhr.addEventListener("loadend", function(e) {
            $("#progress-container").remove();
            var data = JSON.parse(e.target.responseText);
            if (data && data.url) {
              user_subscription.send_message(textarea.val(), conversation_id, new_conversation.val(), user_id.val(), data.url, file_name, file_type, message_id);
              textarea.val('');
              $(".emojionearea-editor").html("");
              file.val('');
            }
          }, false);
          xhr.send(formData);
        } else {
          user_subscription.send_message(textarea.val(), conversation_id, new_conversation.val(), user_id.val(), "", "", "", message_id);
          textarea.val('');
        }
      }
      e.preventDefault();
      return false;
    });
  }
});


