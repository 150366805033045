$(document).on('turbolinks:load', function(){
  $(document).on('change', '#school_therapeutic_focu', function(){
    $.ajax({
      url: '/schools',
      type: "get",
      data: {therapeutic_focu: $(this).val()}
    });
  });

  $('#send-invite-school').click(function(){
    var email = $('#input-email').val();
    const id = parseInt($('#input-email-group').attr('value'));
    const elements = document.getElementsByClassName('job_management_board_filter_role_dropdown_text');
    const role = elements[0].textContent.trim().toLowerCase();
    
    const pattern = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    if(email.length  == 0){
      alert("please enter email address ");
    }
    else{
      if (email.match(pattern)) {
        $.ajax({
          url: '/schools/'+ id +'/invite_user',
          data: { email,role },
          method: 'GET',
          success: function (data) {
            console.log(data)
          },
          error: function (data) {
            console.log(data)
          }
        })
      }
      else{
        alert("please input valid email address like 'abc@gmail.com'");
      }
    }
  })
});
